import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Route } from 'react-router-dom';

export default ({ MobileLayout, DesktopLayout, ...rest }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 650px)',
  });
  if (isTabletOrMobileDevice || !DesktopLayout) {
    return <Route render={props => <MobileLayout {...{ isTabletOrMobileDevice }} {...props} {...rest} />} />;
  }
  return <Route render={props => <DesktopLayout {...{ isTabletOrMobileDevice }} {...props} {...rest} />} />;
};
