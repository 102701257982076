import * as d3 from 'd3';
import { useEffect, useMemo, useState } from 'react';
import { color } from 'sdk/lib/color';

export function useContainerSize(container, onResize) {
  const [[width, height], setWidthHeight] = useState([0, 0]);

  useEffect(() => {
    if (!container.current) return () => {};
    function resize(e) {
      const width = container.current.clientWidth;
      const height = container.current.clientHeight;
      onResize && onResize();
      setWidthHeight([width, height]);
    }
    const to = setTimeout(resize, 100);
    window.addEventListener('resize', resize);
    return () => {
      to && clearTimeout(to);
      window.removeEventListener('resize', resize);
    };
  }, [container, onResize]);
  return [width, height];
}

export function useSvg(container, margin = { top: 0, left: 0, right: 0, bottom: 0 }) {
  const [svg, setSvg] = useState(null);
  const [containerWidth, containerHeight] = useContainerSize(container);
  const current = container.current;
  const width = containerWidth - margin.left - margin.right;
  const height = containerHeight - margin.top - margin.bottom;

  useEffect(() => {
    if (!current || !width || !height) return () => {};

    const newSvg = d3
      .select(current)
      .append('svg')
      .attr('class', 'chart')
      .style('background-color', 'transparent')
      .attr('width', width)
      .attr('height', height);

    setSvg(newSvg);
    return () => {
      setSvg(null);
      d3.select(current)
        .selectAll('svg')
        .remove();
    };
  }, [current, width, height]);

  return [svg, width, height, margin];
}

export function useParcelScales(parcel, width, height) {
  const { line, x, y } = useMemo(() => {
    if (!parcel || !parcel.geometry_area || !width || !height) return {};

    var x = d3
      .scaleLinear()
      .domain(d3.extent([parcel.geometry_area.bbox[0], parcel.geometry_area.bbox[2]]))
      .range([0, width]);
    var y = d3
      .scaleLinear()
      .domain(d3.extent([parcel.geometry_area.bbox[1], parcel.geometry_area.bbox[3]]).reverse())
      .range([0, height]);
    var line = d3
      .line()
      .x(d => x(d[0]))
      .y(d => y(d[1]));

    return { x, y, line };
  }, [parcel, width, height]);

  return [line, x, y, width, height];
}

export function useDrawParcelHintOnSvg(svg, parcel, line, { polygonColor }) {
  useEffect(() => {
    if (!svg || !line || !parcel || !parcel.geometry_area) return () => {};
    parcel.geometry_area.coordinates.map(coords => {
      return svg
        .append('path')
        .style('fill', polygonColor || color('grey'))
        .style('stroke', polygonColor || color('grey'))
        .style('fill-opacity', '.2')
        .style('stroke-opacity', '.9')
        .datum(coords)
        .attr('d', line);
    });
    return () => {
      svg.selectAll('path').remove();
    };
  }, [svg, parcel, line, polygonColor]);
  return null;
}
