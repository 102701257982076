export const AUTHENTICATION_ACTION_LOGOUT = 'AUTHENTICATION_ACTION_LOGOUT';
export const AUTHENTICATION_ACTION_TOKEN_SUCCESS = 'AUTHENTICATION_ACTION_TOKEN_SUCCESS';

const tokenSuccess = payload => ({
  type: AUTHENTICATION_ACTION_TOKEN_SUCCESS,
  payload,
});

export const logout = () => ({
  type: AUTHENTICATION_ACTION_LOGOUT,
});

export default {
  tokenSuccess,
  logout,
};
