import React from 'react';
import { MdArrowForward } from 'react-icons/md';
import { color, size, spacing } from 'sdk';
import styled from 'styled-components';

export default function TaskName({ task, light }) {
  return (
    <Operation>
      <Part {...{ light }}>{task.owner_name}</Part>
      <MdArrowForward style={styles.arrow} size={spacing * 1.5} color={color('orange')} />
      <Part {...{ light }}>{task.client_name}</Part>
    </Operation>
  );
}

const styles = {
  arrow: {
    margin: `0 ${spacing}px`,
  },
};

const Operation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Part = styled.span`
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
  color: ${({ light }) => (light ? color('white') : color('grey'))};
  opacity: ${({ light }) => (light ? 0.9 : 1)};
  font-weight: bold;
  font-size: ${size('m')};
`;
