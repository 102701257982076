import React, { useCallback } from 'react';
import Slide from 'react-reveal/Slide';
import { spacing, zIndex } from 'sdk';
import styled from 'styled-components';
import NavPageHeader from '../../components/header/NavPageHeader';
import Map from '../../components/map/map';
import { useParcelSelection } from './components/effects';
import TaskDisplay from './components/TaskDisplay';

const Task = ({ task, history }) => {
  const goBack = useCallback(() => {
    history && history.push('/');
  }, [history]);
  const [fitTo, parcel, setParcel] = useParcelSelection();
  return (
    <AppWrapper>
      <NavPageHeader {...{ goBack }} title={task.name} />
      <Background>
        <Map fitOn={fitTo} parcels={task.parcels || []} />
      </Background>
      <Content>
        <Slide up>
          <Card>
            <TaskDisplay selectedParcel={parcel} onParcelClick={setParcel} {...{ task, goBack, history }} />
          </Card>
        </Slide>
      </Content>
    </AppWrapper>
  );
};

const AppWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100vh;
  box-sizing: border-box;
`;

const Background = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Content = styled.div`
  background-color: transparent;
  z-index: ${zIndex('map')};
  align-items: stretch;
  width: 600px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

const Card = styled.div`
  margin: ${spacing}px;
  pointer-events: all;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

export default Task;
