import { Button } from 'components';
import React from 'react';
import Fade from 'react-reveal/Fade';
import { color, spacing, zIndex } from 'sdk';
import styled from 'styled-components';
import Expandable from '../../modal/Expandable';
import LayoutDispatcher from '../../responsive/LayoutDispatcher';
import { useOnScrollAndResize } from './effects';

const Mobile = ({ onRequestClose, open, root, actions = [] }) => {
  return (
    <Expandable title={'Actions'} expanded={open} {...{ onRequestClose }}>
      {actions.map(({ label, onClick, color = 'orange' }, index) => (
        <Fade bottom key={`${label}-${open}`} duration={400} delay={index * (index + 200)}>
          <Button backgroundColor={color} {...{ onClick }}>
            {label}
          </Button>
        </Fade>
      ))}
    </Expandable>
  );
};

const Desktop = ({ onRequestClose, open, root, actions = [] }) => {
  useOnScrollAndResize();
  const [top, left] = (() => {
    if (!open) return [0, 0];
    const { x, y, width } = root.current.getBoundingClientRect();
    return [y, x + width];
  })();

  return (
    <React.Fragment>
      {open && (
        <Container {...{ top, left }}>
          {actions.map(({ label, onClick, color = 'orange' }, index) => (
            <Fade key={label} right duration={400} delay={index * (index + 200)}>
              <Button backgroundColor={color} {...{ onClick }}>
                {label}
              </Button>
            </Fade>
          ))}
        </Container>
      )}
      <Overlay onClick={onRequestClose} {...{ open }}></Overlay>
    </React.Fragment>
  );
};

export default function ContextMenu(props) {
  return <LayoutDispatcher {...props} MobileLayout={Mobile} DesktopLayout={Desktop} />;
}

const Container = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left + spacing}px;
  background-color: transparent;
  transition: all 400ms;
  transition-timming-function: ease;
  z-index: ${zIndex('ui', 2)};
`;

const Overlay = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: fixed;
  background-color: ${color('grey', 'darker')};
  opacity: ${({ open }) => (open ? 0.9 : 0)};
  transition: all 200ms;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex('ui', 1)};
`;
