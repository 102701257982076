import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { spacing } from 'sdk';
import { color } from 'sdk/lib/color';
import styled from 'styled-components';
import FilterAndSort from '../../components/filters_sort/FilterAndSort';
import LargeTaskList from '../../components/tasks/LargeTaskList';
import logo from '../../logo.svg';
import TasksSelectors from '../../services/tasks/TasksSelectors';
import FabButton from '../../components/buttons/FabButton';

const TasksMobile = ({ tasks, history, completeTask, setTaskToInProgress, setTaskToTODO, deleteTask }) => {
  const onTaskClick = useCallback(
    taskID => {
      history.push(`/tasks/${taskID}`);
    },
    [history],
  );
  const goToAddTask = useCallback(() => {
    history.push(`/add`);
  }, [history]);

  return (
    <AppWrapper>
      <Logo alt="karnott logo" src={logo} />
      <Header>
        <FilterAndSort />
      </Header>
      <Content>
        <Margin />
        <Board>
          <LargeTaskList
            laneStyle={styles.lane}
            {...{ tasks, completeTask, onTaskClick, setTaskToInProgress, setTaskToTODO, deleteTask }}
          />
        </Board>
      </Content>
      <FabButton onClick={goToAddTask} />
    </AppWrapper>
  );
};

const mapStateToProps = state => ({
  tasks: TasksSelectors.getFilteredAndSortedTasks(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TasksMobile);

const styles = {
  lane: {
    maxHeight: '70vh',
    minWidth: '300px',
    width: '86vw',
    alignItems: 'stretch',
  },
};

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  background-color: ${color('bleen')};
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: ${spacing}px;
`;

const Content = styled.div`
  display: flex;
  align-self: stretch;
  overflow-y: scroll;
  flex-direction: row;
  width: 100vw;
`;

const Margin = styled.div`
  display: flex;
  min-width: 7vw;
`;

const Board = styled.div``;

const Logo = styled.img`
  max-width: 200px;
`;
