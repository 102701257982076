"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ArrowBackIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ArrowBackIcon = function ArrowBackIcon(props) {
  return _react["default"].createElement("svg", {
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 24 24'
  }, _react["default"].createElement("g", null, _react["default"].createElement("title", null, "ic_arrow_back"), _react["default"].createElement("path", {
    fill: "none",
    stroke: props.color,
    strokeLinejoin: "round",
    strokeWidth: "2px",
    strokeLinecap: "round",
    d: "M11.38 18.5L4.88 12l6.5-6.5"
  }), _react["default"].createElement("path", {
    fill: "none",
    stroke: props.color,
    strokeLinejoin: "round",
    strokeWidth: "2px",
    strokeLinecap: "round",
    d: "M5.12 12h14"
  })));
};

exports.ArrowBackIcon = ArrowBackIcon;