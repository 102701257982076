import { AddCircleIcon, Button, EditableString } from 'components';
import find from 'lodash/find';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useContext, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import removeAccents from 'remove-accents';
import Slider from 'react-slick';
import { color, size, spacing } from 'sdk';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import AsyncEffect from '../../components/async/AsyncEffect';
import AddCluster from '../../components/clusters/AddCluster';
import AddDriver from '../../components/clusters/AddDriver';
import PageHeader from '../../components/header/PageHeader';
import Loader from '../../components/loading/Loader';
import { useParcelSelection } from '../../components/map/effects';
import Map from '../../components/map/map';
import ParcelThumbnail from '../../components/parcel/ParcelThumbnail';
import Select from '../../components/select/Select';
import { showSuccessToast } from '../../components/toaster/Toaster';
import ClustersContext from '../../contexts/clusters/ClustersContext';
import EquipmentModelsContext from '../../contexts/equipment/models/EquipmentModelsContext';
import ClustersSelectors from '../../services/clusters/ClustersSelectors';
import EquipmentModelsSelectors from '../../services/equipment_models/EquipmentModelsSelectors';
import MapUtils from '../../services/map/MapUtils';
import ParcelsActioner from '../../services/parcels/ParcelsActioner';
import ParcelsSelectors from '../../services/parcels/ParcelsSelectors';
import TasksActioner from '../../services/tasks/TasksActioner';
import UsersActioner from '../../services/users/UsersActioner';
import UsersSelectors from '../../services/users/UsersSelectors';

function createTaskReducer(state, action) {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload };
    case 'equipment_model_id':
      return { ...state, equipment_model_id: action.payload };
    case 'client_cluster_id':
      return { ...state, client_cluster_id: action.payload };
    case 'assigned_to_id':
      return { ...state, assigned_to_id: action.payload };
    default:
      throw new Error();
  }
}

function AddTask({
  clusterByID,
  clusters,
  createTask,
  equipmentModels,
  fetchAnonymousParcels,
  fetchParcelsByClusterID,
  fetchUsersOfClusters,
  history,
  ownerCluster,
  parcelsByClusterID,
  users,
}) {
  const [slideIndex, setSlideIndex] = useState(0);
  const settings = {
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    infinite: false,
    beforeChange: (current, next) => setSlideIndex(next),
  };
  const setTitle = useCallback(() => {
    switch (slideIndex) {
      case 1:
        document.title = 'Lier des parcelles à la tâche';
        return 'Lier des parcelles à la tâche';
      case 2:
        document.title = 'Valider la nouvelle tâche';
        return 'Valider la nouvelle tâche';
      case 0:
      default:
        document.title = 'Créer une nouvelle tâche';
        return 'Créer une nouvelle tâche';
    }
  }, [slideIndex]);
  let addTaskSlider;
  const { getClusterById } = useContext(ClustersContext);
  const { getEquipmentModelNameById } = useContext(EquipmentModelsContext);

  const goHome = useCallback(() => {
    history && history.push('/');
  }, [history]);
  const [showClusterAddModal, setShowClusterAddModal] = useState(false);
  const [showDriverAddModal, setShowDriverAddModal] = useState(false);

  //toaster
  const taskCreatedToast = useCallback(() => {
    showSuccessToast('Tâche créée!');
  }, []);

  // fetch users
  const adminClusterIDs = useMemo(() => clusters.filter(c => c.role === 'ADMIN').map(c => c.id), [clusters]);
  const fetchUoC = useCallback(() => fetchUsersOfClusters(adminClusterIDs), [adminClusterIDs, fetchUsersOfClusters]);
  const [, ,] = AsyncEffect.useAsyncCall(fetchUoC, true);

  // task local reducer
  const [createTaskReducerState, createTaskReducerDispatch] = useReducer(createTaskReducer, { name: '' });
  const { assigned_to_id, client_cluster_id, equipment_model_id, name } = createTaskReducerState;

  const equipmentModelsOptions = sortBy(
    equipmentModels.map(em => ({ value: em.id, label: getEquipmentModelNameById(em.id) })),
    e => e.label && removeAccents(e.label.toUpperCase()),
  );
  const clustersOptions = clusters.map(c => ({ value: c.id, label: c.name }));
  const clientParcels = (client_cluster_id && parcelsByClusterID[client_cluster_id]) || [];
  const [selection, isLoading] = useParcelSelection(MapUtils.mapInstance, clientParcels, fetchAnonymousParcels);
  const computeTotalArea = useMemo(() => {
    let totalArea = 0;
    selection.forEach(p => (totalArea += p.area));
    return round(totalArea / 10000, 2);
  }, [selection]);
  const driver = find(users, u => u.id === assigned_to_id);

  return (
    <AppWrapper>
      <PageHeader {...{ history }}>
        <AddTaskTitle>
          <ShadowDivForLoader sideOfMargin={'R'}>&nbsp;</ShadowDivForLoader>
          <span>{setTitle()}</span>
          {!!isLoading ? (
            <Loader wrapperStyle={{ marginLeft: `${spacing}px` }} size={48} />
          ) : (
            <ShadowDivForLoader sideOfMargin={'L'}>&nbsp;</ShadowDivForLoader>
          )}
        </AddTaskTitle>
        <div style={{ width: '200px' }}>&nbsp;</div>
      </PageHeader>
      <SlickContainer>
        <Slider ref={slider => (addTaskSlider = slider)} {...settings}>
          <div>
            <AddTaskStep>
              <div>
                <AddTaskInputWrapper>
                  <Select
                    label={'Client'}
                    options={clustersOptions}
                    onChange={c => {
                      if (!parcelsByClusterID[c]) {
                        fetchParcelsByClusterID({ clusterID: c });
                      }
                      createTaskReducerDispatch({ type: 'client_cluster_id', payload: c });
                    }}
                    selected={client_cluster_id}
                    bottomAction={{
                      fn: () => setShowClusterAddModal(true),
                      btnLabel: 'Ajouter un client',
                      ico: <AddCircleIcon size={16} color={color('white')} />,
                    }}
                  />
                  <AddCluster
                    parentClusterID={ownerCluster && ownerCluster.id}
                    addClusterExpanded={showClusterAddModal}
                    setAddClusterExpanded={setShowClusterAddModal}
                    dispatch={createTaskReducerDispatch}
                  />
                </AddTaskInputWrapper>
                <AddTaskInputWrapper>
                  <Select
                    label={'Type'}
                    options={equipmentModelsOptions}
                    onChange={t => {
                      createTaskReducerDispatch({ type: 'equipment_model_id', payload: t });
                    }}
                    selected={equipment_model_id}
                  />
                </AddTaskInputWrapper>
                <AddTaskInputWrapper>
                  <Select
                    label={'Chauffeur (facultatif)'}
                    options={users}
                    onChange={t => {
                      createTaskReducerDispatch({ type: 'assigned_to_id', payload: t });
                    }}
                    selected={assigned_to_id}
                    bottomAction={{
                      fn: () => setShowDriverAddModal(true),
                      btnLabel: 'Ajouter un chauffeur',
                      ico: <AddCircleIcon size={16} color={color('white')} />,
                    }}
                  />
                  <AddDriver
                    clusterID={ownerCluster && ownerCluster.id}
                    addDriverExpanded={showDriverAddModal}
                    setAddDriverExpanded={setShowDriverAddModal}
                    dispatch={createTaskReducerDispatch}
                  />
                </AddTaskInputWrapper>
              </div>
              <FirstStepButtonContainer>
                <Button
                  backgroundColor={'bleen'}
                  disabled={!equipment_model_id || !client_cluster_id}
                  onClick={() => addTaskSlider.slickNext()}
                >
                  Suivant
                </Button>
              </FirstStepButtonContainer>
            </AddTaskStep>
          </div>
          <div>
            <AddTaskMapStep>
              <MapContainer>
                <Map fitOn={clientParcels} />
              </MapContainer>
              <BottomButtons>
                <Button backgroundColor={'bleen'} outline onClick={() => addTaskSlider.slickPrev()}>
                  Précédent
                </Button>
                <Button
                  backgroundColor={'bleen'}
                  onClick={() => {
                    if (!name) {
                      createTaskReducerDispatch({
                        type: 'name',
                        payload: `${getEquipmentModelNameById(equipment_model_id)} - ${
                          clusterByID[client_cluster_id].name
                        }`,
                      });
                    }
                    addTaskSlider.slickNext();
                  }}
                >
                  Suivant
                </Button>
              </BottomButtons>
            </AddTaskMapStep>
          </div>
          <div>
            <AddTaskStep>
              <AddTaskRecapWrapper>
                <AddTaskInputWrapper>
                  <AddTaskInputLabel htmlFor="name_of_task">Nom de la tâche</AddTaskInputLabel>
                  <EditableString
                    value={name}
                    setValue={value => createTaskReducerDispatch({ type: 'name', payload: value })}
                  />
                </AddTaskInputWrapper>
                <AddTaskRecapBloc>
                  <AddTaskRecapItem>
                    <AddTaskRecapLabel>Type</AddTaskRecapLabel>
                    <AddTaskRecapValue>
                      {equipment_model_id ? getEquipmentModelNameById(equipment_model_id) : null}
                    </AddTaskRecapValue>
                  </AddTaskRecapItem>
                  <AddTaskRecapItem>
                    <AddTaskRecapLabel>Client</AddTaskRecapLabel>
                    <AddTaskRecapValue>
                      {client_cluster_id ? getClusterById(client_cluster_id).name : null}
                    </AddTaskRecapValue>
                  </AddTaskRecapItem>
                  <AddTaskRecapItem>
                    <AddTaskRecapLabel>Chauffeur</AddTaskRecapLabel>
                    <AddTaskRecapValue>{driver ? driver.label : null}</AddTaskRecapValue>
                  </AddTaskRecapItem>
                </AddTaskRecapBloc>
                {selection && selection.length ? (
                  <AddTaskRecapBloc>
                    <AddTaskRecapLabel>Parcelles (total : {`${computeTotalArea}`}ha)</AddTaskRecapLabel>

                    <Parcels>
                      {selection.map((parcel, j) => (
                        <ParcelIcon key={`${parcel.id}-${j}`}>
                          <ParcelThumbnail {...{ parcel }} color={color('orange')} />
                        </ParcelIcon>
                      ))}
                    </Parcels>
                  </AddTaskRecapBloc>
                ) : null}
              </AddTaskRecapWrapper>
              <BottomButtons>
                <Button backgroundColor={'bleen'} outline onClick={() => addTaskSlider.slickPrev()}>
                  Précédent
                </Button>
                <Button
                  backgroundColor={'bleen'}
                  disabled={!name || !ownerCluster}
                  onClick={() => {
                    createTask({
                      name,
                      equipment_model_id,
                      owner_cluster_id: ownerCluster.id,
                      status: 'todo',
                      client_cluster_id,
                      assigned_to_id,
                      parcels: selection.map(p => ({ id: p.id })),
                    }).then(() => {
                      taskCreatedToast();
                      document.title = 'Karnott planner';
                      goHome();
                    });
                  }}
                >
                  Valider
                </Button>
              </BottomButtons>
            </AddTaskStep>
          </div>
        </Slider>
      </SlickContainer>
    </AppWrapper>
  );
}

const mapStateToProps = (state, props) => ({
  equipmentModels: EquipmentModelsSelectors.getEquipmentModels(state),
  clusters: ClustersSelectors.getClusters(state),
  clusterByID: state.clusters.clusterByID,
  parcelsByClusterID: ParcelsSelectors.getParcelsByClusterID(state),
  users: UsersSelectors.getUsersFormatted(state),
  ownerCluster: ClustersSelectors.getOwnerCluster(state),
});

const mapDispatchToProps = dispatch => ({
  createTask: payload => dispatch(TasksActioner.createTask(payload)),
  fetchAnonymousParcels: payload => dispatch(ParcelsActioner.fetchAnonymousParcels(payload)),
  fetchParcelsByClusterID: clusterID => dispatch(ParcelsActioner.fetchParcelsByClusterID(clusterID)),
  fetchUsersOfClusters: clusterIDs => dispatch(UsersActioner.fetchUsersOfClusters(clusterIDs)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTask);

const SlickContainer = styled.div`
  max-width: 100%;
  background-color: white;
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  overflow: hidden;
`;

const AddTaskTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  color: #65cec6;
  background-color: ${color('bleen')};
  font-size: ${size('xxl')};
  span {
    font-family: 'Ubuntu', sans-serif;
  }
`;

const ShadowDivForLoader = styled.div`
  width: 48px;
  height: 48px;
  ${({ sideOfMargin }) => (sideOfMargin === 'L' ? `margin-left:${spacing}px;` : `margin-right:${spacing}px;`)};
`;

const AddTaskStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  height: calc(100vh - 82px);
  margin: auto;
  padding: ${spacing / 2}px ${spacing}px;
  position: relative;
`;

const FirstStepButtonContainer = styled.div`
  display: flex;
`;

const AddTaskMapStep = styled(AddTaskStep)`
  max-width: unset;
`;

const AddTaskInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddTaskRecapBloc = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing / 2}px 0;
`;

const MapContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const AddTaskRecapWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${spacing / 2}px;
  left: ${spacing / 2}px;
  right: ${spacing / 2}px;
  z-index: 2;
  button {
    flex: 1;
    &:not(:first-child) {
      margin-left: ${spacing / 4}px;
    }
    &:not(:last-child) {
      margin-right: ${spacing / 4}px;
    }
  }
`;

const AddTaskInputLabel = styled.label`
  font-size: ${size('xs')};
  margin: ${spacing / 2}px ${spacing / 2}px ${spacing / 2}px 0;
  color: ${color('bleen')};
`;

const AddTaskRecapItem = styled.div`
  display: flex;
  align-items: baseline;
  &:not(:last-child) {
    margin-bottom: ${spacing / 2}px;
  }
`;

const AddTaskRecapLabel = styled.div`
  font-size: ${size('xs')};
  color: ${color('bleen')};
  margin-right: ${spacing / 2}px;
`;

const AddTaskRecapValue = styled.div`
  font-size: ${size('m')};
  color: ${color('grey')};
`;

const Parcels = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${spacing / 2}px;
  max-height: calc(100vh - 383px);
  overflow-y: auto;
`;

const ParcelIcon = styled.div`
  display: flex;
  width: ${spacing * 4}px;
  height: ${spacing * 4}px;
  &:not(:last-child) {
    margin-right: ${spacing / 2}px;
  }
`;
