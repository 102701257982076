"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SolIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var SolIcon = function SolIcon(props) {
  return _react["default"].createElement("svg", {
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    strokeWidth: "".concat(props.strokeWidth || 1),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M3.3 24.8v3.6M7.3 26.2v2.2M15.4 26.2v2.2M31.7 26.2v2.2M35.7 24.9v3.5M23.6 26.2v2.2M27.6 26.2v2.2M19.5 26.2v2.2M11.4 26.2v2.2M23.9 19.8h9.5l2.4 4v1.7c0 .2-.2.4-.4.4H3.7c-.2 0-.4-.2-.4-.4v-1.7l2.4-4H15"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M25.3 22.2H13.7l3.9-6.5h3.8zM21.4 15.7h-3.8l1.1-5.1h1.6z"
  }));
};

exports.SolIcon = SolIcon;