"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var DeleteIcon = function DeleteIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("g", null, _react["default"].createElement("path", {
    stroke: props.color,
    strokeWidth: 2,
    d: "M15.89 20.2H8.48A2.68 2.68 0 0 1 6 17.39V7.09c0-.41.2-.41.61-.41H17.4c.41 0 .61 0 .61.41v10.67a2.31 2.31 0 0 1-2.12 2.44z"
  }), _react["default"].createElement("path", {
    fill: "none",
    strokeWidth: 2,
    d: "M5.87 6.68h12.26"
  }), _react["default"].createElement("rect", {
    x: "8.45",
    y: "3.2",
    width: "7.09",
    height: "4.2",
    rx: "1.3",
    ry: "1.3"
  })));
};

exports.DeleteIcon = DeleteIcon;