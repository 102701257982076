"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EditableString = EditableString;

var _react = _interopRequireWildcard(require("react"));

var _sdk = require("sdk");

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _index = require("../index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function useToggle(initialState) {
  var _useState = (0, _react.useState)(initialState),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var toggle = (0, _react.useCallback)(function () {
    setValue(!value);
  }, [value, setValue]);
  return [value, toggle];
}

function useInputValue(initialValue) {
  var _useState3 = (0, _react.useState)(initialValue),
      _useState4 = _slicedToArray(_useState3, 2),
      currentValue = _useState4[0],
      setCurrentValue = _useState4[1];

  var onValueChange = (0, _react.useCallback)(function (e) {
    setCurrentValue(e.target.value);
  }, [setCurrentValue]);
  (0, _react.useEffect)(function () {
    setCurrentValue(initialValue);
  }, [initialValue, setCurrentValue]);
  return [currentValue, onValueChange];
}

function EditableString(_ref) {
  var value = _ref.value,
      setValue = _ref.setValue;

  var _useToggle = useToggle(false),
      _useToggle2 = _slicedToArray(_useToggle, 2),
      displayInput = _useToggle2[0],
      toggleDisplayInput = _useToggle2[1];

  var _useInputValue = useInputValue(value),
      _useInputValue2 = _slicedToArray(_useInputValue, 2),
      displayedValue = _useInputValue2[0],
      onDisplayedValueChanged = _useInputValue2[1];

  var onButtonClick = (0, _react.useCallback)(function () {
    if (displayInput) {
      setValue && setValue(displayedValue);
    }

    toggleDisplayInput();
  }, [displayInput, toggleDisplayInput, setValue, displayedValue]);

  function getDisplayedComponent() {
    if (displayInput) {
      return _react["default"].createElement(Input, {
        onChange: onDisplayedValueChanged,
        autoFocus: true,
        value: displayedValue
      });
    } else {
      return _react["default"].createElement(Div, null, displayedValue);
    }
  }

  return _react["default"].createElement(Container, null, getDisplayedComponent(), _react["default"].createElement(_index.Button, {
    noSvgMargin: true,
    onClick: onButtonClick
  }, displayInput ? _react["default"].createElement(_index.Icons.CheckIcon, {
    color: (0, _sdk.color)('white')
  }) : _react["default"].createElement(_index.Icons.PenIcon, {
    color: (0, _sdk.color)('white')
  })));
}

var Container = _styledComponents["default"].div.withConfig({
  displayName: "EditableString__Container",
  componentId: "enzpzr-0"
})(["display:flex;flex-direction:row;align-items:stretch;"]);

var style = "\n  font-size: ".concat((0, _sdk.size)('m'), ";\n  font-family: ").concat((0, _sdk.family)('body'), ";\n  width: 100%;\n  color: ").concat((0, _sdk.color)('grey'), ";\n  outline: none;\n  border-radius: 4px;\n  margin:4px;\n  padding: ").concat(_sdk.spacing / 2, "px ").concat(_sdk.spacing / 4, "px;\n");

var Input = _styledComponents["default"].input.withConfig({
  displayName: "EditableString__Input",
  componentId: "enzpzr-1"
})(["border:1px solid ", ";", ""], (0, _sdk.color)('bleen', 'light'), style);

var Div = _styledComponents["default"].div.withConfig({
  displayName: "EditableString__Div",
  componentId: "enzpzr-2"
})(["border:1px solid transparent;", ""], style);