import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import AuthenticationSelectors from './services/authentication/AuthenticationSelectors';
import { saveState, store } from './services/store';
import * as serviceWorkers from './serviceWorkers';
import Toaster from './components/toaster/Toaster';

store.subscribe(() => {
  const state = store.getState();
  const authentication = AuthenticationSelectors.getState(state);
  saveState({ authentication });
});

render(
  <Provider {...{ store }}>
    <App />
    <Toaster />
  </Provider>,
  document.getElementById('root'),
);

serviceWorkers.register();
