import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import LayoutDispatcher from '../../components/responsive/LayoutDispatcher';
import AuthenticationActioner from '../../services/authentication/AuthenticationActioner';
import AuthenticationSelectors from '../../services/authentication/AuthenticationSelectors';
import SignInMobile from './SignInMobile';
import LoadingOverlay from '../../components/loading/LoadingOverlay';

function SignIn({ authentication, tokenSuccess, location }) {
  const access_token = useMemo(() => {
    const hash = location.hash;
    const hashValues = hash.replace('#', '').split('&');
    let accessToken = 0;
    for (let v of hashValues) {
      const keyValue = v.split('=');
      if (keyValue[0] === 'access_token') {
        accessToken = keyValue[1];
      }
    }
    return accessToken;
  }, [location]);

  useEffect(() => {
    if (access_token) {
      tokenSuccess({
        token: access_token,
      });
      const referrer = localStorage.getItem('fromURL');
      window.location.replace(decodeURIComponent(referrer || '/'));
    }
    return () => {};
  }, [access_token, tokenSuccess]);
  if (access_token) return <LoadingOverlay />;
  return <LayoutDispatcher MobileLayout={SignInMobile} />;
}

const mapStateToProps = state => ({
  authentication: AuthenticationSelectors.getState(state),
});

const mapDispatchToProps = dispatch => ({
  tokenSuccess: payload => dispatch(AuthenticationActioner.tokenSuccess(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignIn);
