import { useCallback, useEffect, useState } from 'react';

function useAsyncCall(call, onMount) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  const trigger = useCallback(() => {
    setIsLoading(true);
    call()
      .then(response => {
        setValue(response);
        setIsLoading(false);
        setHasBeenCalled(true);
      })
      .catch(error => {
        setHasBeenCalled(true);
        setIsLoading(false);
        setError(error);
      });
  }, [call, setIsLoading, setValue, setError]);

  useEffect(() => {
    if (onMount) trigger();
    return () => {};
  }, [onMount, trigger]);

  return [trigger, value, isLoading, error, hasBeenCalled];
}

function useOnSuccess(call, onSuccess) {
  const trigger = useCallback(
    (...args) => {
      call(...args).then(response => {
        onSuccess && onSuccess();
        return Promise.resolve(response);
      });
    },
    [call, onSuccess],
  );

  return trigger;
}

export default {
  useAsyncCall,
  useOnSuccess,
};
