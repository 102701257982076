import React, { useContext, useMemo } from 'react';
import { color, size, spacing } from 'sdk';
import styled from 'styled-components';
import ClustersContext from '../../contexts/clusters/ClustersContext';
import EquipmentModelsContext from '../../contexts/equipment/models/EquipmentModelsContext';
import { FILTER_TYPES } from '../../services/filters/FiltersReducer';

export default function FilterItem({ filter, onClick }) {
  const { getClusterById } = useContext(ClustersContext);
  const { getEquipmentModelNameById } = useContext(EquipmentModelsContext);
  const name = useMemo(() => {
    const { type } = filter;
    switch (type) {
      case FILTER_TYPES.EQUIPMENT_MODEL:
        return getEquipmentModelNameById(filter.instance_id);
      case FILTER_TYPES.CLUSTER:
        const cluster = getClusterById(filter.instance_id);
        return cluster.name;
      default:
        return filter.label;
    }
  }, [filter, getClusterById, getEquipmentModelNameById]);
  return (
    <Item key={`filters-item-${filter.label}`} onClick={onClick} checked={filter.checked}>
      {name}
    </Item>
  );
}

export const Item = styled.div`
  margin: ${spacing / 2}px;
  padding: ${spacing / 2}px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px ${({ checked }) => (checked ? color('orange') : 'rgba(0, 0, 0, 0.24)')};
  background-color: ${({ checked }) => (checked ? color('orange') : color('white'))};
  color: ${({ checked }) => (checked ? color('white') : color('grey'))};
  font-size: ${size('xs')};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 100ms;
  svg {
    margin-left: ${spacing / 4}px;
  }
`;
