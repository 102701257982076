import { AUTHENTICATION_ACTION_LOGOUT } from '../authentication/AuthenticationActioner';
import {
  EQUIPMENT_MODELS_ACTION_SET_EQUIPMENT_MODELS,
  EQUIPMENT_MODELS_ACTION_SET_ERROR,
  EQUIPMENT_MODELS_ACTION_SET_LOADING,
} from './EquipmentModelsActioner';

let equipmentModelByID = {};
let equipmentModelIDs = [];

const initialState = {
  loading: false,
  error: null,
  equipmentModelByID,
  equipmentModelIDs,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case EQUIPMENT_MODELS_ACTION_SET_LOADING:
      if (payload === state.loading) return state;
      return {
        ...state,
        loading: payload,
      };
    case EQUIPMENT_MODELS_ACTION_SET_EQUIPMENT_MODELS:
      return {
        ...state,
        ...payload,
        loading: false,
        error: null,
      };
    case EQUIPMENT_MODELS_ACTION_SET_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case AUTHENTICATION_ACTION_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
