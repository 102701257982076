import React from 'react';
import LottiAnimation from '../animations/LottieAnimation';

export default function Loader({ wrapperStyle = {}, infinite, size }) {
  const animation = infinite ? require('./infiniteLoader.json') : require('./data.json');
  const config = {
    loop: true,
  };
  return (
    <div style={wrapperStyle}>
      <LottiAnimation width={size} height={size} animationData={animation} config={config} />
    </div>
  );
}
