import omit from 'lodash/omit';
import { AUTHENTICATION_ACTION_LOGOUT } from '../authentication/AuthenticationActioner';
import {
  TASKS_ACTION_DELETE_TASK,
  TASKS_ACTION_SAVE_TASK,
  TASKS_ACTION_SET_ERROR,
  TASKS_ACTION_SET_LOADING,
  TASKS_ACTION_SET_TASKS,
  TASKS_ACTION_UPDATE_TASK,
} from './TasksActioner';
import uniq from 'lodash/uniqBy';

let taskByID = {};
let taskIDs = [];

const initialState = {
  loading: false,
  error: null,
  taskByID,
  taskIDs,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case TASKS_ACTION_SET_LOADING:
      if (payload === state.loading) return state;
      return {
        ...state,
        loading: payload,
      };
    case TASKS_ACTION_SET_TASKS:
      const setTasksTaskById = { ...state.taskByID };
      Object.keys(payload.taskByID).forEach(key => {
        if (!setTasksTaskById[key]) {
          setTasksTaskById[key] = payload.taskByID[key];
        } else {
          setTasksTaskById[key] = {
            ...setTasksTaskById[key],
            ...payload.taskByID[key],
          };
        }
      });
      return {
        taskByID: setTasksTaskById,
        taskIDs: uniq([...state.taskIDs, ...payload.taskIDs]),
        loading: false,
        error: null,
      };
    case TASKS_ACTION_SET_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case TASKS_ACTION_DELETE_TASK:
      const ids = state.taskIDs.filter(i => i !== payload);
      return {
        ...state,
        taskByID: omit(state.taskByID, payload),
        taskIDs: ids,
      };
    case TASKS_ACTION_SAVE_TASK:
      const { id } = payload;
      const saveTaskTaskById = { ...state.taskByID };
      saveTaskTaskById[id] = {
        ...state.taskByID[id],
        ...payload,
      };
      Object.keys(saveTaskTaskById).forEach(key => {
        if (key !== id) {
          delete saveTaskTaskById[key].newly_created;
        }
      });
      return {
        ...state,
        taskIDs: [...state.taskIDs, id],
        taskByID: saveTaskTaskById,
      };
    case TASKS_ACTION_UPDATE_TASK:
      return {
        ...state,
        taskByID: {
          ...state.taskByID,
          [payload.id]: payload,
        },
      };
    case AUTHENTICATION_ACTION_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
