import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AsyncEffect from '../../components/async/AsyncEffect';
import LoadingOverlay from '../../components/loading/LoadingOverlay';
import LayoutDispatcher from '../../components/responsive/LayoutDispatcher';
import TasksActioner from '../../services/tasks/TasksActioner';
import TasksSelectors from '../../services/tasks/TasksSelectors';
import TaskDesktop from './TaskDesktop';
import TaskMobile from './TaskMobile';

function Task({ task, getTask, history }) {
  const [fetchTask, fetchedTask, fetchIsLoading, , fetchHasBeenTried] = AsyncEffect.useAsyncCall(getTask);
  useEffect(() => {
    if (!task) {
      fetchTask();
    }
  }, [task, fetchTask]);

  if ((!task && !fetchHasBeenTried) || fetchIsLoading) return <LoadingOverlay />;
  if (!task && fetchHasBeenTried && !fetchedTask) return <span>404</span>;
  return (
    <LayoutDispatcher
      MobileLayout={TaskMobile}
      task={task || fetchedTask}
      {...{ history }}
      DesktopLayout={TaskDesktop}
    />
  );
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  task: props.match && props.match.params && TasksSelectors.getState(state).taskByID[props.match.params.id],
});

const mapDispatchToProps = (dispatch, props) => ({
  getTask: () => props.match && props.match.params && dispatch(TasksActioner.getTask(props.match.params.id)),
  deleteTask: () => props.match && props.match.params && dispatch(TasksActioner.deleteTask(props.match.params.id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Task);
