"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Button = Button;

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _sdk = require("sdk");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function Button(_ref) {
  var backgroundColor = _ref.backgroundColor,
      children = _ref.children,
      disabled = _ref.disabled,
      onClick = _ref.onClick,
      outline = _ref.outline,
      noSvgMargin = _ref.noSvgMargin;
  return _react["default"].createElement(ButtonContainer, {
    backgroundColor: backgroundColor,
    disabled: disabled,
    onClick: onClick,
    outline: outline,
    noSvgMargin: noSvgMargin
  }, children);
}

var ButtonContainer = _styledComponents["default"].button.withConfig({
  displayName: "Button__ButtonContainer",
  componentId: "sc-1wf6q9q-0"
})(["display:flex;align-items:center;justify-content:center;outline:none;border:1px solid ", ";border-radius:4px;padding:", "px ", "px;flex:1;margin:", "px;background-color:", ";color:", ";font-size:", ";transition:box-shadow 150ms ease-out,transform 150ms ease-out,background 150ms ease-out;cursor:", ";font-weight:600;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;svg{margin-right:", "px;min-width:16px;}&:hover{background-image:none;}&:not(:disabled):hover{transform:translateY(-1px);box-shadow:0 4px 8px rgba(0,0,0,0.1);background-color:", ";color:", ";}"], function (props) {
  return props.disabled ? (0, _sdk.color)('smoke', 'dark') : (0, _sdk.color)(props.backgroundColor || 'orange');
}, _sdk.spacing / 2, _sdk.spacing, _sdk.spacing / 4, function (props) {
  if (props.disabled) {
    return (0, _sdk.color)('snow');
  } else if (props.outline) {
    return 'white';
  } else {
    return (0, _sdk.color)(props.backgroundColor || 'orange');
  }
}, function (props) {
  if (props.disabled) {
    return (0, _sdk.color)('smoke');
  } else if (props.outline) {
    return (0, _sdk.color)(props.backgroundColor || 'orange');
  } else {
    return 'white';
  }
}, (0, _sdk.size)('m'), function (props) {
  return props.disabled ? 'not-allowed' : 'pointer';
}, function (_ref2) {
  var noSvgMargin = _ref2.noSvgMargin;
  return noSvgMargin ? 0 : _sdk.spacing / 2;
}, function (props) {
  if (props.outline) {
    return 'white';
  } else if (props.backgroundColor) {
    return (0, _sdk.color)(props.backgroundColor, 'light');
  } else {
    return (0, _sdk.color)('orange');
  }
}, function (props) {
  if (props.outline) {
    return (0, _sdk.color)(props.backgroundColor, 'light');
  } else {
    return 'white';
  }
});