import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { zIndex } from 'sdk';
import 'react-toastify/dist/ReactToastify.css';
import './toaster.css';

toast.configure();

const Config = {
  className: 'toaster',
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  closeButton: false,
  draggable: true,
};

export function showSuccessToast(content, params = {}) {
  return toast.success(content, {
    ...Config,
    className: 'toaster-success',
    ...params,
  });
}

export function showInfoToast(content, params = {}) {
  return toast.success(content, {
    ...Config,
    className: 'toaster-info',
    ...params,
  });
}

export function showDeleteToast(content, params = {}) {
  return toast.error(content, {
    ...Config,
    className: 'toaster-delete',
    ...params,
  });
}

export default function Toaster() {
  return <ToastContainer style={{ toast: { backgroundColor: 'black', zIndex: zIndex('toaster') } }} />;
}
