import React from 'react';
import { connect } from 'react-redux';
import ClustersSelectors from '../../services/clusters/ClustersSelectors';
import Context from './ClustersContext';

function ClustersProvider({ clusters, children }) {
  function getClusterById(clusterId) {
    return clusters.clusterByID[clusterId];
  }

  return (
    <Context.Provider
      value={{
        getClusterById,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const ReduxConnectedProvider = connect(
  state => ({
    clusters: ClustersSelectors.getState(state),
  }),
  dispatch => ({}),
)(ClustersProvider);

export default ReduxConnectedProvider;
