export const FILTERS_ACTION_RESET_FILTERS = 'FILTERS_ACTION_RESET_FILTERS';
export const FILTERS_ACTION_TOGGLE_FILTER_ITEM = 'FILTERS_ACTION_TOGGLE_FILTER_ITEM';

const toggleFilterItem = payload => ({
  type: FILTERS_ACTION_TOGGLE_FILTER_ITEM,
  payload,
});

const resetFilters = () => ({
  type: FILTERS_ACTION_RESET_FILTERS,
});

export default {
  toggleFilterItem,
  resetFilters,
};
