import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { color, size, spacing } from 'sdk';
import styled from 'styled-components';
import EquipmentModelBookmark from '../../../components/equipment/EquipmentModelBookmark';
import ParcelItem from '../../../components/parcel/ParcelItem';
import TaskName from '../../../components/tasks/TaskName';
import TasksActioner from '../../../services/tasks/TasksActioner';
import TasksUtils from '../../../services/tasks/TasksUtils';
import { ChevronDownIcon } from 'components';

function useToggle(initialState) {
  const [value, setValue] = useState(initialState);
  const toggle = useCallback(() => {
    setValue(!value);
  }, [value, setValue]);
  return [value, toggle];
}

function TaskDisplay({ task, onParcelClick, selectedParcel }) {
  const stats = useMemo(() => {
    return TasksUtils.getTaskProgression(task);
  }, [task]);
  const [isOpen, toggleOpen] = useToggle(true);
  const hasDriver = useMemo(() => !!(task && task.assigned_to_email && task.assigned_to_email.trim()), [task]);
  return (
    <Container>
      <TaskHeader>
        <EquipmentModelBookmark modelId={task.equipment_model_id} />
        <HeaderText>
          <Matters>{`${stats.totalWorkedArea} /`}</Matters>
          {`${stats.totalArea} ha traités `}
        </HeaderText>
        <ExpandIcon onClick={toggleOpen} isUp={isOpen}>
          <ChevronDownIcon size={32} color={color('grey', 'dark')} />
        </ExpandIcon>
      </TaskHeader>

      <DetailsContainer {...{ isOpen }}>
        <TaskName {...{ task }} />
        <Detail>
          <RowHeader>CHAUFFEUR</RowHeader>
          <RowValue>{hasDriver ? task.assigned_to_email : 'non renseigné'}</RowValue>
        </Detail>

        {task.parcels && <RowHeader>PARCELLES</RowHeader>}
        <Parcels>
          {task.parcels &&
            task.parcels.map(parcel => (
              <ParcelItem
                selected={selectedParcel && selectedParcel.id === parcel.id}
                onClick={onParcelClick}
                key={parcel.id}
                {...{ parcel }}
              />
            ))}
        </Parcels>
      </DetailsContainer>
    </Container>
  );
}

const mapDispatchToProps = (dispatch, props) => ({
  deleteTask: taskID => dispatch(TasksActioner.deleteTask(taskID)),
});

export default connect(
  null,
  mapDispatchToProps,
)(TaskDisplay);

const Container = styled.div`
  overflow: hidden;
  position: relative;
  padding: ${spacing}px;
  display: flex;
  flex-direction: column;
  transition: all 100ms ease;
`;

const TaskHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DetailsContainer = styled.div`
  flex-direction: column;
  margin-top: ${spacing / 2}px;
  transition: all 100ms ease;
  max-height: ${({ isOpen }) => (isOpen ? '100vh' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  margin-top: ${({ isOpen }) => (isOpen ? `${spacing / 2}px` : '0')};
`;

const Detail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing / 2}px 0;
`;

const Parcels = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-height: 25vh;
`;

const ExpandIcon = styled.div`
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: ${({ isUp }) => (isUp ? 'none' : 'rotate(180deg)')};
`;

const RowHeader = styled.h4`
  color: ${color('smoke', 'dark')};
  margin: 0;
`;

const HeaderText = styled.span`
  color: ${color('smoke', 'dark')};
  font-size: ${size('xl')};
  max-width: 80vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Matters = styled.span`
  margin: 0 4px 0 0;
  color: ${color('green')};
  font-weight: bold;
`;

const RowValue = styled.span``;
