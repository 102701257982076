import { AUTHENTICATION_ACTION_LOGOUT } from '../authentication/AuthenticationActioner';
import {
  CLUSTERS_ACTION_SET_CLUSTER,
  CLUSTERS_ACTION_SET_CLUSTERS,
  CLUSTERS_ACTION_SET_ERROR,
  CLUSTERS_ACTION_SET_LOADING
} from "./ClustersActioner";

let clusterByID = {};
let clusterIDs = [];

const initialState = {
  loading: false,
  error: null,
  clusterByID,
  clusterIDs,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case CLUSTERS_ACTION_SET_LOADING:
      if (payload === state.loading) return state;
      return {
        ...state,
        loading: payload,
      };
    case CLUSTERS_ACTION_SET_CLUSTERS:
      return {
        ...state,
        ...payload,
        loading: false,
        error: null,
      };
    case CLUSTERS_ACTION_SET_CLUSTER:
      const { id } = payload;
      return {
        ...state,
        clusterIDs: [...state.clusterIDs, id],
        clusterByID: {
          ...state.clusterByID,
          [id]: {
            ...state.clusterByID[id],
            ...payload,
          },
        },
      };
    case CLUSTERS_ACTION_SET_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case AUTHENTICATION_ACTION_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
