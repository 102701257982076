import { createSelector } from 'reselect';
import find from 'lodash/find';

const getState = state => state.clusters;

const getClusters = createSelector(
  [getState],
  clusters => {
    return clusters.clusterIDs.map(id => clusters.clusterByID[id]);
  },
);

const getOwnerCluster = createSelector(
  [getClusters],
  clusters => {
    return find(clusters, c => c.cluster_type === 'ETA' && c.role === 'ADMIN');
  },
);

export default {
  getState,
  getClusters,
  getOwnerCluster,
};
