import React, { useMemo } from 'react';
import styled from 'styled-components';
import ParcelItem from '../../parcel/ParcelItem';
import { size, color } from 'sdk';

export default function({ parcels }) {
  const displayedParcels = useMemo(() => {
    return !parcels ? null : parcels.slice().splice(0, 3);
  }, [parcels]);
  if (!displayedParcels) return null;
  return (
    <List>
      {displayedParcels.map(parcel => {
        return <ParcelItem key={parcel.id} light {...{ parcel }} />;
      })}
      {displayedParcels && displayedParcels.length < parcels.length && (
        <SeeMore>Voir plus ({parcels.length - displayedParcels.length}) ...</SeeMore>
      )}
    </List>
  );
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: stretch;
`;
const SeeMore = styled.span`
  font-size: ${size('m')};
  color: ${color('smoke', 'dark')};
  cursor: pointer;
`;
