import { Button, AddCircleIcon } from 'components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { spacing, size } from 'sdk';
import { color } from 'sdk/lib/color';
import styled from 'styled-components';
import FilterAndSort from '../../components/filters_sort/FilterAndSort';
import LargeTaskList from '../../components/tasks/LargeTaskList';
import logo from '../../logo.svg';
import TasksSelectors from '../../services/tasks/TasksSelectors';
import TextAnimation from '../../components/animations/TextAnimation';

const TasksDesktop = ({ tasks, history, completeTask, setTaskToInProgress, setTaskToTODO, deleteTask }) => {
  const onTaskClick = useCallback(taskID => history.push(`/tasks/${taskID}`), [history]);
  const goHome = useCallback(() => history.push(`/`), [history]);
  const goToAddTask = useCallback(() => {
    history.push(`/add`);
  }, [history]);
  return (
    <AppWrapper>
      <Header>
        <Logo alt="karnott logo" src={logo} onClick={goHome} />
        <AnimationContainer>
          <TextAnimation color={'#65CEC6'} noMargin fontSize={'xxl'} />
        </AnimationContainer>
        <div style={{ width: '200px' }}>&nbsp;</div>
      </Header>
      <BoardWrapper>
        <Bleed />
        <Board>
          <HeaderActions>
            <FilterAndSort />
            <Button backgroundColor={'orange'} onClick={goToAddTask}>
              <AddCircleIcon size={size('l')} />
              Ajouter une tâche
            </Button>
          </HeaderActions>
          <LargeTaskList
            laneStyle={styles.lane}
            {...{ tasks, completeTask, onTaskClick, setTaskToInProgress, setTaskToTODO, deleteTask }}
          />
        </Board>
        <Bleed />
      </BoardWrapper>
    </AppWrapper>
  );
};

const mapStateToProps = state => ({
  tasks: TasksSelectors.getFilteredAndSortedTasks(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TasksDesktop);

const styles = {
  lane: {
    maxHeight: '80vh',
    marginRight: spacing + 'px',
    marginLeft: spacing + 'px',
  },
};

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${color('bleen')};
  background-image: url(./kplan-bg.jpg);
  background-size: cover;
  background-position: bottom;
`;

const Board = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BoardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
`;

const AnimationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Bleed = styled.div`
  min-width: 200px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  margin-bottom: ${spacing}px;
  margin-right: ${spacing}px;
  max-width: 100vw;
`;

const HeaderActions = styled.div`
  display: flex;
  align-self: flex-end;
  margin-right: ${spacing}px;
`;

const Logo = styled.img`
  cursor: pointer;
  max-width: 200px;
`;
