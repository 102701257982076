import AuthenticationSelectors from '../authentication/AuthenticationSelectors';
import ParcelsApi from './ParcelsApi';
import ParcelsUtils from './ParcelsUtils';

export const PARCELS_ACTION_SET_PARCELS = 'PARCELS_ACTION_SET_PARCELS';
export const PARCELS_ACTION_SET_LOADING = 'PARCELS_ACTION_SET_LOADING';
export const PARCELS_ACTION_SET_ERROR = 'PARCELS_ACTION_SET_ERROR';

const setLoading = payload => ({
  type: PARCELS_ACTION_SET_LOADING,
  payload,
});

const setError = payload => ({
  type: PARCELS_ACTION_SET_ERROR,
  payload,
});

const setParcels = payload => ({
  type: PARCELS_ACTION_SET_PARCELS,
  payload,
});

const fetchParcelsByClusterID = payload => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setLoading(true));
    return dispatch(ParcelsApi.getParcelsByClusterID(AuthenticationSelectors.getState(state), payload.clusterID))
      .then(result => {
        const parcels = ParcelsUtils.serializeParcelsFromApi(result);
        dispatch(setParcels(parcels));
        return Promise.resolve(parcels);
      })
      .catch(e => {
        dispatch(setError(e));
        return Promise.reject(e);
      });
  };
};

const fetchAnonymousParcels = payload => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setLoading(true));
    return dispatch(ParcelsApi.getAnonymousParcels(AuthenticationSelectors.getState(state), payload)).then(result => {
      const parcels = ParcelsUtils.serializeAnonParcelsFromApi(result);
      return Promise.resolve(parcels);
    });
  };
};

export default {
  fetchAnonymousParcels,
  fetchParcelsByClusterID,
};
