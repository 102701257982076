import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { spacing, zIndex } from 'sdk';
import styled from 'styled-components';
import logo from '../../logo.svg';

function PageHeader({ children, history }) {
  const goHome = useCallback(() => history.push(`/`), [history]);
  return (
    <Header>
      <Logo alt="karnott logo" src={logo} onClick={goHome} />
      {children}
    </Header>
  );
}

export default connect(
  null,
  null,
)(PageHeader);

const Header = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: ${spacing}px;
  width: 100%;
  max-width: 100vw;
  z-index: ${zIndex('header')};
  align-items: stretch;
  margin-bottom: ${spacing}px;
  margin-right: ${spacing}px;
`;

const Logo = styled.img`
  cursor: pointer;
  max-width: 200px;
`;
