import { ArrowBackIcon, CloseIcon } from 'components';
import React from 'react';
import { color, size, spacing, zIndex } from 'sdk';
import styled from 'styled-components';

export default function NavPageHeader({ goBack, title }) {
  return (
    <Header>
      <TopBar>
        <Title>{title}</Title>
        <GoBack onClick={goBack}>
          <ArrowBackIcon color={color('white')} size={size('xl')} />
        </GoBack>
        <GoBack onClick={goBack}>
          <CloseIcon color={color('white')} size={size('xl')} />
        </GoBack>
      </TopBar>
    </Header>
  );
}
const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: ${zIndex('header')};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  position: relative;
  color: ${color('white')};
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: ${size('s')};
  color: white;
  background-color: ${color('bleen')};
  padding: ${spacing}px;
`;

const GoBack = styled.div`
  z-index: ${zIndex('header', 1)};
  cursor: pointer;
`;

const Title = styled.div`
  font-size: ${size('xl')};
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
`;
