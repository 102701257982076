function serializeClustersFromApi(apiClusters) {
  const clusterByID = {};
  const clusterIDs = [];
  (apiClusters || []).forEach(c => {
    clusterIDs.push(c.id);
    clusterByID[c.id] = c;
  });
  return { clusterByID, clusterIDs };
}

export default {
  serializeClustersFromApi,
};
