import React, { useRef } from 'react';
import styled from 'styled-components';
import MapUtils from '../../services/map/MapUtils';
import { useFitMapOnParcels, useMapCarrier, useDrawParcelsOnMap, useDrawParcelsTracksOnMap } from './effects';
import ZoomControls from './zoom_controls';
import GeolocationButton from './GeolocationButton';

function Map({ fitOn, parcels }) {
  const mapRef = useRef(null);
  const { mapCarrier, mapInstance } = MapUtils;
  mapInstance && mapInstance.invalidateSize();

  useMapCarrier(mapRef, mapCarrier, mapInstance);
  useDrawParcelsOnMap(mapInstance, parcels);
  useDrawParcelsTracksOnMap(mapInstance, parcels);
  useFitMapOnParcels(mapInstance, fitOn || parcels);

  return (
    <React.Fragment>
      <MapContainer ref={mapRef} />
      <ZoomControls />
      <GeolocationButton />
    </React.Fragment>
  );
}

const MapContainer = styled.div`
  flex: 1;
`;

export default Map;
