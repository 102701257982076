import React from 'react';
import { Icons } from 'components';
import styled from 'styled-components';
import { spacing, color, zIndex } from 'sdk';
import Bounce from 'react-reveal/Bounce';

export default function FabButton({ onClick, relative }) {
  const Component = relative ? RelativeContainer : FixedContainer;
  return (
    <Component {...{ onClick }}>
      <Bounce delay={200} force>
        <Icon>
          <Icons.PlusIcon color={color('white')} size={30} />
        </Icon>
      </Bounce>
    </Component>
  );
}

const Container = styled.div`
  z-index: ${zIndex('fab')};
`;

const FixedContainer = styled(Container)`
  position: fixed;
  bottom: ${spacing}px;
  right: ${spacing}px;
`;

const RelativeContainer = styled.div(`

`);

const Icon = styled.div`
  display: flex;
  background-color: ${color('orange')};
  width: ${spacing * 3}px;
  height: ${spacing * 3}px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 50%;
`;
