import { karnottAPI } from 'sdk';
import api from '../api/ApiUtils';

export function getParcelsByClusterID(authentication, clusterID) {
  return api(karnottAPI.parcels.get.byClusterID, {
    baseURI: process.env.REACT_APP_API_BASE,
    params: {
      cluster_id: clusterID,
    },
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
  });
}

export function getAnonymousParcels(authentication, nesw) {
  return api(karnottAPI.parcels.get.anonymousParcels, {
    baseURI: process.env.REACT_APP_API_BASE,
    params: {
      n: nesw.n,
      e: nesw.e,
      s: nesw.s,
      w: nesw.w,
    },
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
  });
}

export default {
  getAnonymousParcels,
  getParcelsByClusterID,
};
