import { size } from 'sdk';
import styled from 'styled-components';

export default styled.button`
  background-color: ${({ secondary, backgroundColor }) =>
    backgroundColor || (secondary ? 'white' : '#8cc046')} !important;
  border: none;
  outline: none;
  color: ${({ secondary, textColor }) => textColor || (secondary ? 'black' : 'white')} !important;
  padding: 0.5em 1em;
  font-size: ${size('m')};
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 3px ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'rgba(0, 0, 0, 0.12)')},
      0 1px 2px ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'rgba(0, 0, 0, 0.24)')};
  }
`;
