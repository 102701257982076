import { LocationIcon } from 'components';
import React, { useEffect } from 'react';
import { color, size, spacing } from 'sdk';
import styled from 'styled-components';
import MapUtils from '../../services/map/MapUtils';
import Loader from '../loading/Loader';
import { useGeolocation } from './effects';

const ControlWrapper = styled.div`
  position: absolute;
  right: ${spacing / 2}px;
  bottom: ${52 + 4.5 * spacing}px;
  z-index: 500;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const Control = styled.div`
  width: ${2 * spacing}px;
  height: ${2 * spacing}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color('smoke')};
  border-radius: 4px;
  color: ${color('grey')};
  font-size: ${size('xl')};
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${color('smoke', 'dark')};
  }
`;

export function GeolocationControl() {
  const [getLocation, status, hasAsked, location] = useGeolocation();
  useEffect(() => {
    if (!location || (!location.latitude && !location.longitude) || !hasAsked) return () => {};
    MapUtils.mapInstance.flyTo([location.latitude, location.longitude], 16);
    return () => {};
  }, [location, hasAsked]);
  if (!navigator || !navigator.geolocation) return null;
  return (
    <ControlWrapper disabled={status === 'denied'} onClick={getLocation}>
      <Control>{status === 'undetermined' && hasAsked ? <Loader /> : <LocationIcon />}</Control>
    </ControlWrapper>
  );
}

export default GeolocationControl;
