import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
require('leaflet-hotline');
require('leaflet.gridlayer.googlemutant');

const Leaflet = L;

const mapCarrier = (() => {
  let element = document.getElementById('mapCarrier');
  if (!element) {
    element = document.createElement('div');
    element.setAttribute('id', 'mapCarrier');
  }
  return element;
})();

mapCarrier &&
  (mapCarrier.onTouchMove = e => {
    e.stopPropagation();
  });

const tilesLayer = Leaflet.gridLayer.googleMutant({
  type: 'hybrid',
});

const mapInstance = new Leaflet.Map(mapCarrier, {
  center: [48.833, 2.333],
  zoom: 7,
  zoomControl: false,
});

mapInstance.addLayer(tilesLayer);

const limitAnonZoom = () => {
  const width = window.innerWidth;
  if (width < 480) {
    return 15;
  } else if (width > 480 && width <= 768) {
    return 14;
  } else {
    return 13;
  }
};

export default {
  limitAnonZoom,
  Leaflet,
  mapCarrier,
  mapInstance,
};
