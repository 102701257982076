function serializeParcelsFromApi(apiParcels) {
  const parcelByID = {};
  const parcelByClusterID = {};
  const parcelIDs = [];
  const parcelClusterIDs = [];
  (apiParcels || []).forEach(p => {
    p.geometry_area = JSON.parse(p.geometry_area);
    parcelIDs.push(p.id);
    parcelClusterIDs.push(p.cluster_id);
    parcelByID[p.id] = p;
    if (!parcelByClusterID[p.cluster_id]) {
      parcelByClusterID[p.cluster_id] = [];
    }
    parcelByClusterID[p.cluster_id].push(p);
  });
  return { parcelByClusterID, parcelByID, parcelClusterIDs, parcelIDs };
}

function serializeAnonParcelsFromApi(anonParcels) {
  return anonParcels.map(ap => ({
    ...ap,
    geometry_area: JSON.parse(ap.geometry_area),
  }));
}

export default {
  serializeAnonParcelsFromApi,
  serializeParcelsFromApi,
};
