import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { color } from 'sdk/lib/color';
import MapUtils from '../../services/map/MapUtils';
import AsyncEffect from '../async/AsyncEffect';
import { buildParcelStyle } from '../parcel/style';

export function useMapCarrier(mapNode, carrier, mapInstance) {
  useEffect(() => {
    mapNode.current && carrier && mapNode.current.replaceWith(carrier);
    carrier && (carrier.style.visibility = 'visible');
    carrier && (carrier.style.display = 'block');
    mapInstance && mapInstance.invalidateSize();
    return () => {
      carrier && (carrier.style.visibility = 'invisible');
      carrier && (carrier.style.display = 'none');
    };
  }, [mapNode, carrier, mapInstance]);
}

export function useDrawParcelsOnMap(map, parcels, onParcelClick) {
  useEffect(() => {
    if (!map || !parcels || !parcels.length) return () => {};
    const layer = MapUtils.Leaflet.geoJSON(
      parcels.map((p, index) => ({
        ...p.geometry_area,
        properties: {
          ...p.geometry_area.properties,
          index,
          parcel_id: p.id,
          cluster_id: p.cluster_id,
          checked: p.checked,
        },
      })),
      {
        style: feature => buildParcelStyle(feature.geometry.properties),
        onEachFeature: (f, l) => {
          l.on('click', () => {
            if (!!onParcelClick) {
              const theParcel = parcels.find(({ id }) => id === f.properties.parcel_id);
              onParcelClick(theParcel);
            }
          });
        },
      },
    );
    layer.addTo(map);
    map && map.invalidateSize();
    return () => {
      layer.removeFrom(map);
    };
  }, [map, parcels, onParcelClick]);
}

export function useDrawParcelsTracksOnMap(map, parcels) {
  useEffect(() => {
    if (!map || !parcels || !parcels.length) return () => {};
    const layer = MapUtils.Leaflet.geoJSON(
      parcels.filter(p => !!p.track).map((p, index) => p.track),
      {
        style: function(feature) {
          return {
            fillColor: 'transparent',
            strokeColor: color('green'),
            color: color('green'),
            weight: 1,
          };
        },
      },
    );
    layer.addTo(map);
    map && map.invalidateSize();
    return () => {
      layer.removeFrom(map);
    };
  }, [map, parcels]);
}

export function useFitMapOnParcels(map, parcels) {
  const points = useMemo(() => {
    return parcels
      .reduce((value, parcel) => {
        return value.concat(parcel.geometry_area.coordinates[0]);
      }, [])
      .map(coords => coords.slice().reverse());
  }, [parcels]);

  useEffect(() => {
    map && map.invalidateSize();
    map &&
      points &&
      points.length &&
      map.fitBounds(points, {
        animate: true,
        paddingBottomRight: [0, 250],
        paddingTopLeft: [0, 50],
        maxZoom: 18,
      });
  }, [map, points]);
}

export function useCallOnMapMove(mapInstance, call) {
  const callbackForCall = useCallback(() => {
    const bounds = mapInstance.getBounds();
    if (mapInstance.getZoom() >= MapUtils.limitAnonZoom()) {
      return call({
        n: bounds.getNorth(),
        e: bounds.getEast(),
        s: bounds.getSouth(),
        w: bounds.getWest(),
      });
    } else {
      return Promise.resolve();
    }
  }, [call, mapInstance]);

  const [trigger, value, isCallLoading, error] = AsyncEffect.useAsyncCall(callbackForCall, false);

  let timeoutRef = useRef(0);
  let [isInTimeout, setIsInTimeout] = useState(false);
  useEffect(() => {
    const timeoutTrigger = () => {
      clearTimeout(timeoutRef.current);
      if (mapInstance.getZoom() >= MapUtils.limitAnonZoom()) {
        setIsInTimeout(true);
      }
      timeoutRef.current = setTimeout(() => {
        if (mapInstance.getZoom() >= MapUtils.limitAnonZoom()) {
          trigger();
          setIsInTimeout(false);
        }
      }, 1000);
    };
    mapInstance.on('dragend', timeoutTrigger);
    mapInstance.on('zoomend', timeoutTrigger);
    return () => {
      mapInstance.off('dragend', timeoutTrigger);
      mapInstance.off('zoomend', timeoutTrigger);
    };
  }, [trigger, mapInstance, setIsInTimeout, timeoutRef]);

  const isLoading = isCallLoading || isInTimeout;
  return [value, isLoading, error];
}

export function useParcelSelection(mapInstance, userParcels, fetchAnonymousParcel) {
  const [state, dispatch] = useReducer((parcels, action) => {
    const { parcel, type } = action;
    switch (type) {
      case 'toggle':
        const indexOfParcel = parcels.findIndex(p => p.id === parcel.id);
        if (indexOfParcel !== -1) {
          let newParcels = parcels.slice();
          newParcels.splice(indexOfParcel, 1);
          return newParcels;
        } else {
          parcel.checked = true;
          return parcels.concat([parcel]);
        }
      default:
        return parcels;
    }
  }, []);
  const addSelectedParcel = useCallback(parcel => {
    dispatch({ type: 'toggle', parcel });
  }, []);
  const [anonymousParcels, isLoading, error] = useCallOnMapMove(mapInstance, fetchAnonymousParcel);

  const [anonWithoutCheckedParcels, usersWithoutCheckedParcels] = useMemo(
    () => [
      (anonymousParcels || [])
        .filter(ap => !state.map(p => p.id).includes(ap.id))
        .map(ap => ({ ...ap, checked: false })),
      (userParcels || []).filter(up => !state.map(p => p.id).includes(up.id)).map(p => ({ ...p, checked: false })),
    ],
    [state, anonymousParcels, userParcels],
  );
  useDrawParcelsOnMap(mapInstance, anonWithoutCheckedParcels, addSelectedParcel);
  useDrawParcelsOnMap(mapInstance, usersWithoutCheckedParcels, addSelectedParcel);
  useDrawParcelsOnMap(mapInstance, state, addSelectedParcel);
  return [state, isLoading, error];
}

export function useGeolocation() {
  const [location, setLocation] = useState(null);
  const [status, setStatus] = useState('undetermined');
  const [asked, setAsked] = useState(null);

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const pos = position.coords;
        setLocation(pos);
      });
      setAsked(new Date());
    }
  }, [setLocation, setAsked]);

  useEffect(() => {
    if (!navigator.geolocation || !asked) return () => {};
    const id = navigator.geolocation.watchPosition(
      function(position) {
        setStatus('granted');
      },
      function(error) {
        if (error.code === error.PERMISSION_DENIED) {
          setStatus('denied');
        }
      },
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
  }, [setStatus, asked]);

  return [getLocation, status, asked, location];
}
