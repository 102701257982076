import { Dropdown, LogoutIcon, PersonIcon } from 'components';
import { default as React, useCallback } from 'react';
import { connect } from 'react-redux';
import { spacing, zIndex } from 'sdk';
import { color } from 'sdk/lib/color';
import styled from 'styled-components';
import AsyncEffect from '../../components/async/AsyncEffect';
import LayoutDispatcher from '../../components/responsive/LayoutDispatcher';
import { showDeleteToast, showInfoToast, showSuccessToast } from '../../components/toaster/Toaster';
import AuthenticationActioner from '../../services/authentication/AuthenticationActioner';
import TasksActioner from '../../services/tasks/TasksActioner';
import TasksDesktop from './TasksDesktop';
import TasksMobile from './TasksMobile';

function Tasks({ fetchTasks, completeTask, setTaskToTODO, setTaskToInProgress, logout, deleteTask }) {
  const [, , ,] = AsyncEffect.useAsyncCall(fetchTasks, true);

  const taskToTODOSuccessToast = useCallback(() => {
    showInfoToast('Tâche à faire!');
  }, []);
  const taskToInProgressSuccessToast = useCallback(() => {
    showInfoToast('Tâche en cours!');
  }, []);
  const taskCompleteSuccessToast = useCallback(() => {
    showSuccessToast('Tâche terminée!');
  }, []);
  const taskDeletedToast = useCallback(() => {
    showDeleteToast('Tâche suprimée!');
  }, []);

  const toastedSetTaskToTODO = AsyncEffect.useOnSuccess(setTaskToTODO, taskToTODOSuccessToast);
  const toastedSetTaskToInProgress = AsyncEffect.useOnSuccess(setTaskToInProgress, taskToInProgressSuccessToast);
  const toastedDeleteTask = AsyncEffect.useOnSuccess(deleteTask, taskDeletedToast);
  const toastedCompleteTask = AsyncEffect.useOnSuccess(completeTask, taskCompleteSuccessToast);

  return (
    <React.Fragment>
      <SettingsIcon>
        <Dropdown
          head={<PersonIcon color={color('white')} />}
          items={[{ label: 'DÉCONNEXION', logo: <LogoutIcon color={color('white')} />, onClick: logout }]}
        />
      </SettingsIcon>

      <LayoutDispatcher
        MobileLayout={TasksMobile}
        DesktopLayout={TasksDesktop}
        setTaskToTODO={toastedSetTaskToTODO}
        setTaskToInProgress={toastedSetTaskToInProgress}
        completeTask={toastedCompleteTask}
        deleteTask={toastedDeleteTask}
      />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(AuthenticationActioner.logout()),
  fetchTasks: () => dispatch(TasksActioner.fetchTasks()),
  deleteTask: id => dispatch(TasksActioner.deleteTask(id)),
  setTaskToTODO: taskId => dispatch(TasksActioner.setTaskStatus(taskId, 'todo')),
  completeTask: taskId => dispatch(TasksActioner.setTaskStatus(taskId, 'done')),
  setTaskToInProgress: taskId => dispatch(TasksActioner.setTaskStatus(taskId, 'in_progress')),
});

const SettingsIcon = styled.div`
  position: absolute;
  top: ${spacing}px;
  right: ${spacing}px;
  cursor: pointer;
  z-index: ${zIndex('fab')};
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tasks);
