"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpreaderIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var SpreaderIcon = function SpreaderIcon(props) {
  return _react["default"].createElement("svg", {
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    strokeWidth: "".concat(props.strokeWidth || 1),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M33 20.5h.7c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2H24M15.9 16.8c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2S22 26.4 22 23s-2.7-6.2-6.1-6.2zm0 8.6c-1.3 0-2.4-1.1-2.4-2.4 0-1.3 1.1-2.4 2.4-2.4s2.4 1.1 2.4 2.4c-.1 1.3-1.1 2.4-2.4 2.4zM22 23h10.2M8 23h1.4M33 20.5v-4.3c0-1.3-1-2.3-2.3-2.3H10.5"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M10.5 14l.7-2.6c.1-.4-.1-.9-.6-1l-1.3-.5c-.4-.1-.9.1-1 .6l-.7 2.4c-.2.6-.7 1-1.3 1.1h-.5l.4.3c.5.4.7 1 .5 1.6-.2.6-.7 1-1.3 1.1H5l.4.3c.5.4.7 1 .5 1.6-.2.6-.7 1-1.3 1.1h-.5l.4.3c.5.4.7 1 .5 1.6l-.9 3.2c-.1.4.1.9.6 1l1.4.5c.4.1.9-.1 1-.6l.9-3"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M18.5 14l2.2-2.8c.6-.8 1.9-.8 2.5-.1l2.5 2.9M25.9 14l1.7-2.1c.5-.6 1.4-.6 1.9-.1l1.9 2.2"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M13.1 14l1.7-2.1c.5-.6 1.4-.6 1.9-.1l1.9 2.2"
  }));
};

exports.SpreaderIcon = SpreaderIcon;