"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var CheckIcon = function CheckIcon(_ref) {
  var color = _ref.color,
      size = _ref.size;
  return _react["default"].createElement("svg", {
    viewBox: "0 0 24 24",
    width: "".concat(size || 24),
    height: "".concat(size || 24)
  }, _react["default"].createElement("g", null, _react["default"].createElement("title", null, "ic_check"), _react["default"].createElement("path", {
    fill: "none",
    stroke: color,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2px",
    d: "M4.5 12.43l4.72 4.72L19.5 6.86"
  })));
};

exports.CheckIcon = CheckIcon;