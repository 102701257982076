function serializeUsersFromApi(apiUsers) {
  const userByID = {};
  const userIDs = [];
  (apiUsers || []).forEach(apiUser => {
    userIDs.push(apiUser.id);
    userByID[apiUser.id] = apiUser;
  });
  return { userByID, userIDs };
}

export default {
  serializeUsersFromApi,
};
