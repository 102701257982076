import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { color } from 'sdk';
import styled from 'styled-components';
import RaisedButton from '../../components/buttons/RaisedButton';
import { OAUTH_URL } from '../../constants';
import logo from '../../logo.svg';
import AuthenticationActioner from '../../services/authentication/AuthenticationActioner';
import AuthenticationSelectors from '../../services/authentication/AuthenticationSelectors';
import AuthenticationUtils from '../../services/authentication/AuthenticationUtils';
import TextAnimation from '../../components/animations/TextAnimation';

function SignInMobile({ authentication, history, location }) {
  const isConnected = !!(authentication && authentication.token);

  const requestToken = useCallback(() => {
    const fromURL = location.state ? location.state.referrer : '/';
    localStorage.setItem('fromURL', fromURL);
    AuthenticationUtils.redirectToOAuth(OAUTH_URL);
  }, [location]);
  return (
    <Container>
      <Logo alt="logo" src={logo} />

      <TextAnimation />
      <Fade delay={2000}>
        {isConnected ? (
          <RaisedButton onClick={() => history.push('/')}>Retour à l'accueil</RaisedButton>
        ) : (
          <RaisedButton onClick={requestToken}>Se connecter à Karnott</RaisedButton>
        )}
      </Fade>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20vh;
  flex-direction: column;
  height: 100vh;
  img {
    opacity: 0.4;
  }
  animation: bg 26s ease infinite;
  background: linear-gradient(310deg, ${color('bleen')}, ${color('bleen', 'dark')});
  @keyframes bg {
    0% {
      background-position: 0% 67%;
    }
    50% {
      background-position: 100% 34%;
    }
    100% {
      background-position: 0% 67%;
    }
  }
`;

const Logo = styled.img`
  max-width: 350px;
`;

const mapStateToProps = state => ({
  authentication: AuthenticationSelectors.getState(state),
});

const mapDispatchToProps = dispatch => ({
  tokenSuccess: payload => dispatch(AuthenticationActioner.tokenSuccess(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInMobile);
