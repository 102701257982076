"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogoutIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var LogoutIcon = function LogoutIcon(_ref) {
  var color = _ref.color,
      size = _ref.size;
  return _react["default"].createElement("svg", {
    viewBox: "0 0 24 24",
    width: "".concat(size || 24),
    height: "".concat(size || 24)
  }, _react["default"].createElement("g", null, _react["default"].createElement("title", null, "ic_logout"), _react["default"].createElement("g", null, _react["default"].createElement("g", null, _react["default"].createElement("path", {
    fill: 'none',
    stroke: color,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2px",
    d: "M17.15 9.15L20 12l-2.85 2.85"
  })), _react["default"].createElement("path", {
    fill: 'none',
    stroke: color,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2px",
    d: "M20 12h-9.85"
  }), _react["default"].createElement("path", {
    fill: color,
    fillRule: 'evenodd',
    d: "M14.31 16.7V20H6V4h8.31v3.3"
  }))));
};

exports.LogoutIcon = LogoutIcon;