import AuthenticationSelectors from '../authentication/AuthenticationSelectors';
import TasksApi from './TasksApi';
import TasksUtils from './TasksUtils';
import TasksSelectors from './TasksSelectors';

export const TASKS_ACTION_DELETE_TASK = 'TASKS_ACTION_DELETE_TASK';
export const TASKS_ACTION_SAVE_TASK = 'TASKS_ACTION_SAVE_TASK';
export const TASKS_ACTION_SET_STATUS_OF_TASK_TO = 'TASKS_ACTION_SET_STATUS_OF_TASK_TO';
export const TASKS_ACTION_SET_LOADING = 'TASKS_ACTION_SET_LOADING';
export const TASKS_ACTION_SET_ERROR = 'TASKS_ACTION_SET_ERROR';
export const TASKS_ACTION_SET_TASKS = 'TASKS_ACTION_SET_TASKS';
export const TASKS_ACTION_UPDATE_TASK = 'TASKS_ACTION_UPDATE_TASK';

const setStatusOfTaskTo = payload => ({
  type: TASKS_ACTION_SET_STATUS_OF_TASK_TO,
  payload,
});

const saveTask = payload => ({
  type: TASKS_ACTION_SAVE_TASK,
  payload,
});

const setLoading = payload => ({
  type: TASKS_ACTION_SET_LOADING,
  payload,
});

const setError = payload => ({
  type: TASKS_ACTION_SET_ERROR,
  payload,
});

const setTasks = payload => ({
  type: TASKS_ACTION_SET_TASKS,
  payload,
});

const updateTask = payload => ({
  type: TASKS_ACTION_UPDATE_TASK,
  payload,
});

const deleteTaskSuccess = payload => ({
  type: TASKS_ACTION_DELETE_TASK,
  payload,
});

const fetchTasks = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.tasks.loading) {
      dispatch(setLoading(true));
      return dispatch(TasksApi.getTasks(AuthenticationSelectors.getState(state)))
        .then(result => {
          const tasks = TasksUtils.serializeTasksFromApi(result);
          dispatch(setTasks(tasks));
          return Promise.resolve(tasks);
        })
        .catch(e => {
          dispatch(setError(e));
          return Promise.reject(e);
        });
    } else {
      return Promise.resolve();
    }
  };
};

const createTask = payload => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setLoading(true));
    return dispatch(TasksApi.createTask(AuthenticationSelectors.getState(state), payload))
      .then(result => {
        const task = TasksUtils.serializeTaskFromApi(result);
        task.newly_created = true;
        dispatch(saveTask(task));
        return Promise.resolve(task);
      })
      .catch(e => {
        dispatch(setError(e));
        return Promise.reject(e);
      });
  };
};

const getTask = taskId => {
  return (dispatch, getState) => {
    const state = getState();
    const task = TasksSelectors.getState(state).taskByID[taskId];
    if (task) return Promise.resolve(task);
    return dispatch(TasksApi.getTask(AuthenticationSelectors.getState(state), taskId))
      .then(result => {
        const task = TasksUtils.serializeTaskFromApi(result);
        return Promise.resolve(task);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  };
};

const deleteTask = taskId => {
  return (dispatch, getState) => {
    const state = getState();
    return dispatch(TasksApi.deleteTask(AuthenticationSelectors.getState(state), taskId))
      .then(() => {
        dispatch(deleteTaskSuccess(taskId));
        return Promise.resolve();
      })
      .catch(e => {
        return Promise.reject(e);
      });
  };
};

const setTaskStatus = (taskId, status) => {
  return (dispatch, getState) => {
    const state = getState();
    return dispatch(TasksApi.updateTask(AuthenticationSelectors.getState(state), taskId, { status }))
      .then(result => {
        const task = TasksUtils.serializeTaskFromApi(result);
        dispatch(updateTask(task));
        return Promise.resolve(task);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  };
};

export default {
  createTask,
  deleteTask,
  fetchTasks,
  setStatusOfTaskTo,
  saveTask,
  getTask,
  setTaskStatus,
};
