import moment from 'moment';
import { createSelector } from 'reselect';
import FiltersSelectors from '../filters/FiltersSelectors';
import SortSelectors from '../sort/SortSelectors';

const getState = state => state.tasks;

const getFilteredAndSortedTasks = createSelector(
  [FiltersSelectors.getState, getState, SortSelectors.getState],
  (filters, tasks, sort) => {
    return tasks.taskIDs
      .map(id => tasks.taskByID[id])
      .filter(t => {
        let valid = [];
        Object.keys(filters).forEach((k, i) => {
          valid[i] = false;
          const items = filters[k].items;
          const checkedFilters = items.filter(item => !!item.checked);
          if (checkedFilters.length === 0 || checkedFilters.length === items.length) {
            valid[i] = true;
          } else {
            valid[i] = items.some(item => item.checked && t[k] === item.label);
          }
        });
        return !valid.some(b => !b);
      })
      .sort((a, b) => {
        let condition = a.id - b.id;
        Object.keys(sort).forEach((k, i) => {
          sort[k].items.forEach(item => {
            if (item.checked) {
              switch (item.label) {
                case 'Date de création':
                  condition = moment(b.due_date).isBefore(moment(a.due_date));
                  break;
                case 'Nom':
                  condition = a.name > b.name;
                  break;
                case 'Client':
                  condition = a.client_cluster_id < b.client_cluster_id;
                  break;
                default:
                  condition = a.id < b.id;
                  break;
              }
            }
          });
        });
        return condition ? 1 : -1;
      });
  },
);

export default {
  getState,
  getFilteredAndSortedTasks,
};
