"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddCircleIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var AddCircleIcon = function AddCircleIcon(props) {
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 24 24"
  }, props, {
    fill: props.color,
    height: "".concat(props.size || 24),
    width: "".concat(props.size || 24)
  }), _react["default"].createElement("g", null, _react["default"].createElement("path", {
    d: "M19.07 4.93A10 10 0 1 0 4.93 19.07 10 10 0 1 0 19.07 4.93zM16.5 13H13v3.5a1 1 0 0 1-2 0V13H7.5a1 1 0 0 1 0-2H11V7.5a1 1 0 0 1 2 0V11h3.5a1 1 0 0 1 0 2z"
  })));
};

exports.AddCircleIcon = AddCircleIcon;