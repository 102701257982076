import React from 'react';
import Board from 'react-trello';
import { useFormatTasksForKanban } from './effects';
import TaskCard from './TaskCard';

function LargeTaskList({
  tasks,
  onTaskClick,
  completeTask,
  laneStyle,
  setTaskToTODO,
  setTaskToInProgress,
  deleteTask,
}) {
  const data = useFormatTasksForKanban(tasks);

  return (
    <Board
      style={styles.board}
      data={data}
      components={{
        Card: p => (
          <TaskCard
            {...p}
            onDelete={deleteTask}
            setTaskToCompleted={completeTask}
            setTaskToInProgress={setTaskToInProgress}
            setTaskToToDo={setTaskToTODO}
          />
        ),
      }}
      laneStyle={laneStyle || styles.laneStyle}
      onCardClick={onTaskClick}
      handleDragEnd={(cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
        if (sourceLaneId === targetLaneId) return false;
        if (targetLaneId === 'todo') {
          setTaskToTODO && setTaskToTODO(cardId);
          return true;
        }
        if (targetLaneId === 'in_progress') {
          setTaskToInProgress && setTaskToInProgress(cardId);
          return true;
        }
        if (targetLaneId === 'done') {
          completeTask && completeTask(cardId);
          return true;
        }
        return false;
      }}
    />
  );
}

const styles = {
  board: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
    flex: 1,
    height: 'none',
  },
  laneStyle: { maxHeight: '80vh' },
};

export default LargeTaskList;
