"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "color", {
  enumerable: true,
  get: function get() {
    return _color.color;
  }
});
Object.defineProperty(exports, "karnottAPI", {
  enumerable: true,
  get: function get() {
    return _api.karnottAPI;
  }
});
exports.zIndex = exports.spacing = exports.size = exports.family = void 0;

var _color = require("./color");

var _api = require("./api");

var spacing = 16;
exports.spacing = spacing;
var sizes = {
  xxxxl: 48,
  xxxl: 40,
  xxl: 30,
  xl: 22,
  l: 18,
  m: 16,
  s: 14,
  xs: 12,
  xxs: 10
};
var zIndexValues = {
  ui: 1000,
  fab: 2000,
  modal: 3000,
  header: 4000,
  toaster: 5000
};

var zIndex = function zIndex(input) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return zIndexValues[input] + offset || 1000;
};

exports.zIndex = zIndex;

var size = function size(input) {
  return "".concat(sizes[input] || sizes.m, "px");
};

exports.size = size;
var fontsFamilies = {
  system: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  ui: '"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  body: '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
};

var family = function family(name) {
  return fontsFamilies[name] || fontsFamilies.body;
};

exports.family = family;