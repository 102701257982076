"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MoreVertIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var MoreVertIcon = function MoreVertIcon(_ref) {
  var color = _ref.color,
      size = _ref.size;
  return _react["default"].createElement("svg", {
    viewBox: "0 0 24 24",
    width: "".concat(size || 24),
    height: "".concat(size || 24)
  }, _react["default"].createElement("g", null, _react["default"].createElement("title", null, "ic_more_vert"), _react["default"].createElement("g", null, _react["default"].createElement("circle", {
    fill: color,
    strocke: color,
    cx: "12",
    cy: "6",
    r: "2"
  }), _react["default"].createElement("circle", {
    fill: color,
    strocke: color,
    cx: "12",
    cy: "12",
    r: "2"
  }), _react["default"].createElement("circle", {
    fill: color,
    strocke: color,
    cx: "12",
    cy: "18",
    r: "2"
  }))));
};

exports.MoreVertIcon = MoreVertIcon;