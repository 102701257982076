import { createSelector } from 'reselect';

export const getState = state => state.equipment_models;

const getEquipmentModels = createSelector(
  [getState],
  equipmentModels => {
    return equipmentModels.equipmentModelIDs.map(id => equipmentModels.equipmentModelByID[id]);
  },
);

export default {
  getState,
  getEquipmentModels,
};
