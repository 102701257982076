import React from 'react';
import { Icons } from 'components';
import { connect } from 'react-redux';
import EquipmentModelsSelectors from '../../../services/equipment_models/EquipmentModelsSelectors';
import Context from './EquipmentModelsContext';

function EquipmentModelsProvider({ models, children }) {
  function getModelById(modelId) {
    return models.equipmentModelByID[modelId.toString()];
  }

  function getModelIconById(modelId) {
    const { type } = getModelById(modelId);
    return {
      charrue: Icons.PlowIcon,
      andaineuse: Icons.HayMakingIcon,
      faucheuse: Icons.HayMakingIcon,
      presse: Icons.HayMakingIcon,
      faneuse: Icons.HayMakingIcon,
      tractor: Icons.TractorIcon,
      misc: Icons.MiscellaneousIcon,
      broy: Icons.ChipperIcon,
      remork: Icons.TrailerIcon,
      epand: Icons.SpreaderIcon,
      plomb: Icons.FieldRollerIcon,
      recolt: Icons.HarvestIcon,
      pulve: Icons.PulveIcon,
      semoir: Icons.SeederIcon,
      sol: Icons.SolIcon,
      stone_picker: Icons.MiscellaneousIcon,
      semoir_couverts: Icons.SeederIcon,
      haymaking: Icons.HayMakingIcon,
    }[type];
  }

  function getEquipmentModelNameById(modelId) {
    const { type } = getModelById(modelId);
    return {
      charrue: 'charrue',
      andaineuse: 'andaineuse',
      faucheuse: 'faucheuse',
      presse: 'presse',
      faneuse: 'faneuse',
      tractor: 'tracteur',
      misc: 'divers',
      broy: 'broyeur/Épareus',
      remork: 'remorque',
      epand: 'épandage',
      plomb: 'plombage',
      recolt: 'matériel de récolte',
      pulve: 'pulvérisateur',
      semoir: 'semoir',
      sol: 'travail du sol',
      stone_picker: 'ramasseuse de pierres',
      semoir_couverts: 'Semoir de couvert',
      haymaking: 'fenaison',
    }[type];
  }

  return (
    <Context.Provider
      value={{
        models,
        getModelById,
        getModelIconById,
        getEquipmentModelNameById,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const ReduxConnectedProvider = connect(
  state => ({
    models: EquipmentModelsSelectors.getState(state),
  }),
  dispatch => ({}),
)(EquipmentModelsProvider);

export default ReduxConnectedProvider;
