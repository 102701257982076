import { AUTHENTICATION_ACTION_LOGOUT } from '../authentication/AuthenticationActioner';
import { USERS_ACTION_SET_ERROR, USERS_ACTION_SET_LOADING, USERS_ACTION_SET_UOC } from './UsersActioner';

let userByID = {};
let userIDs = [];

const initialState = {
  loading: false,
  error: null,
  userByID,
  userIDs,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case USERS_ACTION_SET_LOADING:
      if (payload === state.loading) return state;
      return {
        ...state,
        loading: payload,
      };
    case USERS_ACTION_SET_UOC:
      return {
        ...state,
        ...payload,
        loading: false,
        error: null,
      };
    case USERS_ACTION_SET_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case AUTHENTICATION_ACTION_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
