"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocationIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var LocationIcon = function LocationIcon(_ref) {
  var color = _ref.color,
      size = _ref.size;
  return _react["default"].createElement("svg", {
    viewBox: "0 0 24 24",
    width: "".concat(size || 24),
    height: "".concat(size || 24)
  }, _react["default"].createElement("g", null, _react["default"].createElement("title", null, "ic_location"), _react["default"].createElement("path", {
    fill: color,
    stroke: "none",
    d: "M12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm8.94 3A9 9 0 0 0 13 3.06V1h-2v2.06A9 9 0 0 0 3.06 11H1v2h2.06A9 9 0 0 0 11 20.94V23h2v-2.06A9 9 0 0 0 20.94 13H23v-2zM12 19a7 7 0 1 1 7-7 7 7 0 0 1-7 7z"
  })));
};

exports.LocationIcon = LocationIcon;