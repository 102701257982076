import { karnottAPI } from 'sdk';
import api from '../api/ApiUtils';

export function getClusters(authentication) {
  return api(karnottAPI.clusters.get.all, {
    baseURI: process.env.REACT_APP_API_BASE,
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
  });
}

export function createCluster(authentication, payload) {
  return api(karnottAPI.clusters.post.create, {
    baseURI: process.env.REACT_APP_API_BASE,
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
    payload,
  });
}

export function linkCluster(authentication, payload) {
  return api(karnottAPI.clusters.post.linkCluster, {
    baseURI: process.env.REACT_APP_API_BASE,
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
    params: payload,
  });
}

export default {
  createCluster,
  getClusters,
  linkCluster,
};
