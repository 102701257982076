"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HorizontalProgress = HorizontalProgress;

var _react = _interopRequireDefault(require("react"));

var _sdk = require("sdk");

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function HorizontalProgress(_ref) {
  var _ref$progress = _ref.progress,
      progress = _ref$progress === void 0 ? 0 : _ref$progress,
      height = _ref.height,
      rounded = _ref.rounded,
      displayValue = _ref.displayValue;

  function formatProgress(progress) {
    return Math.min(100, Math.max(0, progress * 100)).toFixed(0);
  }

  return _react["default"].createElement(Container, null, displayValue && _react["default"].createElement(Hint, null, formatProgress(progress), "%"), _react["default"].createElement(ProgressContainer, {
    height: height,
    rounded: rounded
  }, _react["default"].createElement(Progression, {
    progress: progress
  })));
}

var Container = _styledComponents["default"].div.withConfig({
  displayName: "horizontalProgress__Container",
  componentId: "sc-1yhmmks-0"
})(["font-family:", ";position:relative;"], (0, _sdk.family)('ui'));

var Hint = _styledComponents["default"].span.withConfig({
  displayName: "horizontalProgress__Hint",
  componentId: "sc-1yhmmks-1"
})(["position:absolute;top:", "px;font-size:", ";right:", "px;color:", ";"], -3 * _sdk.spacing / 2.5, (0, _sdk.size)('s'), _sdk.spacing / 4, (0, _sdk.color)('green'));

var ProgressContainer = _styledComponents["default"].div.withConfig({
  displayName: "horizontalProgress__ProgressContainer",
  componentId: "sc-1yhmmks-2"
})(["display:flex;flex-direction:row;border-radius:", "px;overflow:hidden;height:", "px;background-color:", ";"], function (_ref2) {
  var rounded = _ref2.rounded;
  return rounded ? _sdk.spacing : 0;
}, function (_ref3) {
  var height = _ref3.height;
  return height || _sdk.spacing / 2;
}, (0, _sdk.color)('smoke', 'light'));

var Progression = _styledComponents["default"].div.withConfig({
  displayName: "horizontalProgress__Progression",
  componentId: "sc-1yhmmks-3"
})(["display:flex;flex:", ";transition:all 1s;background-color:", ";"], function (_ref4) {
  var progress = _ref4.progress;
  return progress;
}, (0, _sdk.color)('green'));