import AuthenticationSelectors from '../authentication/AuthenticationSelectors';
import EquipmentModelsApi from './EquipmentModelsApi';
import EquipmentModelsUtils from './EquipmentModelsUtils';

export const EQUIPMENT_MODELS_ACTION_SET_EQUIPMENT_MODELS = 'EQUIPMENT_MODELS_ACTION_SET_EQUIPMENT_MODELS';
export const EQUIPMENT_MODELS_ACTION_SET_LOADING = 'EQUIPMENT_MODELS_ACTION_SET_LOADING';
export const EQUIPMENT_MODELS_ACTION_SET_ERROR = 'EQUIPMENT_MODELS_ACTION_SET_ERROR';

const setLoading = payload => ({
  type: EQUIPMENT_MODELS_ACTION_SET_LOADING,
  payload,
});

const setError = payload => ({
  type: EQUIPMENT_MODELS_ACTION_SET_ERROR,
  payload,
});

const setEquipmentModels = payload => ({
  type: EQUIPMENT_MODELS_ACTION_SET_EQUIPMENT_MODELS,
  payload,
});

const fetchEquipmentModels = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setLoading(true));
    return dispatch(EquipmentModelsApi.getEquipmentModels(AuthenticationSelectors.getState(state)))
      .then(result => {
        const equipmentModels = EquipmentModelsUtils.serializeEquipmentModelsFromApi(result);
        dispatch(setEquipmentModels(equipmentModels));
        return Promise.resolve(equipmentModels);
      })
      .catch(e => {
        dispatch(setError(e));
        return Promise.reject(e);
      });
  };
};

export default {
  fetchEquipmentModels,
};
