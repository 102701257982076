import { karnottAPI } from 'sdk';
import api from '../api/ApiUtils';

export function getEquipmentModels(authentication) {
  return api(karnottAPI.equipment_models.get.all, {
    baseURI: process.env.REACT_APP_API_BASE,
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
  });
}

export default {
  getEquipmentModels,
};
