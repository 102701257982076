"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlowIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var PlowIcon = function PlowIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    strokeWidth: "".concat(props.strokeWidth || 1),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("circle", {
    cx: 32.4,
    cy: 25.7,
    r: 0.6,
    stroke: "transparent",
    fill: props.color
  }), _react["default"].createElement("ellipse", {
    fill: "transparent",
    stroke: props.color,
    cx: 32.4,
    cy: 25.7,
    rx: 4.1,
    ry: 4.2
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M2.5 11.2l7.9 3.8M30.8 21.8L27 17.4H10.4V15h22v6.4"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M10.4 17.4H2.5l7.9-2.4"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M15.2 15c-1.2-4.5-4.8-5.9-4.8-5.9V15h4.8zM26.5 15c-1.2-4.5-4.8-5.9-4.8-5.9V15h4.8zM20.9 15c-1.2-4.5-4.8-5.9-4.8-5.9V15h4.8zM10.4 17.4v5.9s3.6-1.4 4.8-5.9h-4.8zM21.8 17.4v5.9s3.6-1.4 4.8-5.9h-4.8zM16.1 17.4v5.9s3.6-1.4 4.8-5.9h-4.8z"
  }));
};

exports.PlowIcon = PlowIcon;