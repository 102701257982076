import { color } from 'sdk';

export function buildParcelStyle(parcelProperties) {
  if (parcelProperties.checked) {
    return {
      strokeColor: color('green', 'light'),
      color: color('green'),
      fillColor: color('green'),
      fillOpacity: 0.2,
      weight: 1,
      zIndex: 203,
    };
  }
  if (!parcelProperties.cluster_id) {
    return {
      strokeColor: color('orange', 'dark'),
      color: color('orange'),
      fillColor: color('orange'),
      fillOpacity: 0.2,
      weight: 1,
    };
  }
  return {
    strokeColor: color('cyan', 'light'),
    fillColor: color('cyan'),
    color: color('cyan'),
    fillOpacity: 0.2,
    weight: 1,
  };
}
