"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChevronDownIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ChevronDownIcon = function ChevronDownIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 24 24'
  }, _react["default"].createElement("path", {
    d: "M1.70710678,0.292893219 C1.31658249,-0.0976310729 0.683417511,-0.0976310729 0.292893219,0.292893219 C-0.0976310729,0.683417511 -0.0976310729,1.31658249 0.292893219,1.70710678 L5.29289322,6.70710678 C5.68341751,7.09763107 6.31658249,7.09763107 6.70710678,6.70710678 L11.7071068,1.70710678 C12.0976311,1.31658249 12.0976311,0.683417511 11.7071068,0.292893219 C11.3165825,-0.0976310729 10.6834175,-0.0976310729 10.2928932,0.292893219 L6,4.58578644 L1.70710678,0.292893219 Z",
    transform: "translate(6 9)"
  }));
};

exports.ChevronDownIcon = ChevronDownIcon;