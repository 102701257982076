import React from 'react';
import { color, spacing, zIndex } from 'sdk';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export default function Expandable({ expanded, children, onRequestClose, title }) {
  return (
    <Container {...{ expanded }}>
      <Content>
        <Header>
          <Title>{title}</Title>
          <MdClose onClick={onRequestClose} cursor="pointer" size={30} color={color('grey', 'dark')} />
        </Header>
        {children}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: ${props => (props.expanded ? '0' : '100vh')};
  opacity: ${props => (props.expanded ? '1' : '0')};
  left: 0;
  padding: ${spacing}px;
  width: 100vw;
  height: ${props => (props.expanded ? '100vh' : '0')};
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${zIndex('modal')};
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h3`
  color: ${color('grey')};
`;

const Content = styled.div`
  background-color: ${color('snow', 'light')};
  display: flex;
  max-width: 600px;
  width: 90vw;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0);
  padding: ${spacing}px;
  padding-top: 0;
  border-radius: 4px;
  flex-direction: column;
`;
