import AuthenticationSelectors from '../authentication/AuthenticationSelectors';
import ClustersApi from './ClustersApi';
import ClustersUtils from './ClustersUtils';

export const CLUSTERS_ACTION_SET_CLUSTERS = 'CLUSTERS_ACTION_SET_CLUSTERS';
export const CLUSTERS_ACTION_SET_CLUSTER = 'CLUSTERS_ACTION_SET_CLUSTER';
export const CLUSTERS_ACTION_SET_LOADING = 'CLUSTERS_ACTION_SET_LOADING';
export const CLUSTERS_ACTION_SET_ERROR = 'CLUSTERS_ACTION_SET_ERROR';

const setLoading = payload => ({
  type: CLUSTERS_ACTION_SET_LOADING,
  payload,
});

const setError = payload => ({
  type: CLUSTERS_ACTION_SET_ERROR,
  payload,
});

const setClusters = payload => ({
  type: CLUSTERS_ACTION_SET_CLUSTERS,
  payload,
});

const setCluster = payload => ({
  type: CLUSTERS_ACTION_SET_CLUSTER,
  payload,
});

const fetchClusters = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setLoading(true));
    return dispatch(ClustersApi.getClusters(AuthenticationSelectors.getState(state)))
      .then(result => {
        const clusters = ClustersUtils.serializeClustersFromApi(result);
        dispatch(setClusters(clusters));
        return Promise.resolve(clusters);
      })
      .catch(e => {
        dispatch(setError(e));
        return Promise.reject(e);
      });
  };
};

const createCluster = payload => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setLoading(true));
    return dispatch(ClustersApi.createCluster(AuthenticationSelectors.getState(state), { name: payload.name }))
      .then(result => {
        return dispatch(
          ClustersApi.linkCluster(AuthenticationSelectors.getState(state), {
            child_id: result.id,
            parent_id: payload.parentClusterID,
          }),
        )
          .then(() => {
            dispatch(setCluster(result));
            return Promise.resolve(result);
          })
          .catch(e => {
            dispatch(setError(e));
            return Promise.reject(e);
          });
      })
      .catch(e => {
        dispatch(setError(e));
        return Promise.reject(e);
      });
  };
};

export default {
  createCluster,
  fetchClusters,
};
