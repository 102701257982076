function getTaskProgression(task) {
  if (!task) return 0;
  if (!task.parcels || task.parcels.length === 0) {
    if (task.status === Status.in_progress) {
      return {
        ratio: (Math.random() * 99 + 1) / 100,
      };
    } else if (task.status === Status.done) {
      return {
        ratio: 1,
        totalPercent: 100,
        totalWorkedArea: 0,
        totalArea: 0,
      };
    } else {
      return {
        ratio: 0,
        totalPercent: 0,
        totalWorkedArea: 0,
        totalArea: 0,
      };
    }
  }
  const allAreas = task.parcels.reduce((a, { area = 0 }) => area + a, 0);
  const workedArea = task.parcels.reduce((a, { worked_area = 0 }) => worked_area + a, 0);
  return {
    ratio: !allAreas ? 0 : workedArea / allAreas,
    totalPercent: parseInt((100 * (workedArea / allAreas)).toFixed(0)),
    totalWorkedArea: (workedArea / 10000).toFixed(0),
    totalArea: (allAreas / 10000).toFixed(0),
  };
}

function serializeTasksFromApi(apiTasks) {
  const taskByID = {};
  const taskIDs = [];
  (apiTasks || []).forEach(apiTask => {
    const task = serializeTaskFromApi(apiTask);
    taskIDs.push(task.id);
    taskByID[task.id] = task;
  });
  return { taskByID, taskIDs };
}

function serializeTaskFromApi(task) {
  return {
    ...task,
    parcels: (task.parcels || []).map(parcel => ({
      ...parcel,
      geometry_area: JSON.parse(parcel.geometry_area),
      track: parcel.track ? JSON.parse(parcel.track) : null,
      worked_area: determineRandomWorkedArea(task.status, parcel), // todo remove when no more fake data needed
    })),
  };
}

function determineRandomWorkedArea(status, parcel) {
  const { area, worked_area } = parcel;
  switch (status) {
    case Status.todo:
      return 0;
    case Status.in_progress:
      if (!!worked_area) {
        return worked_area;
      } else {
        return ((Math.random() * 99 + 1) * area) / 100;
      }
    case Status.done:
    default:
      return area;
  }
}

const Status = {
  todo: 'todo',
  in_progress: 'in_progress',
  done: 'done',
};

export default {
  getTaskProgression,
  serializeTasksFromApi,
  serializeTaskFromApi,
  Status,
};
