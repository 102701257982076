import { AUTHENTICATION_ACTION_LOGOUT } from '../authentication/AuthenticationActioner';
import { SORT_ACTION_RESET_SORT, SORT_ACTION_TOGGLE_SORT_ITEM } from './SortActioner';

const initialState = {
  time: {
    label: 'Trier par',
    items: [
      {
        label: 'Date de création',
        checked: false,
      },
      {
        label: 'Nom',
        checked: false,
      },
      {
        label: 'Client',
        checked: false,
      },
    ],
  },
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SORT_ACTION_TOGGLE_SORT_ITEM:
      const { label, title } = payload;
      const items = state[title].items;
      let itemToUpdate, indexOfItemToUpdate;
      items.forEach((item, indexOfItem) => {
        if (item.label === label) {
          itemToUpdate = item;
          indexOfItemToUpdate = indexOfItem;
        }
      });
      if (itemToUpdate && typeof indexOfItemToUpdate === 'number' && indexOfItemToUpdate > -1) {
        return {
          ...state,
          [title]: {
            ...state[title],
            items: updateObjectInArray(items, { index: indexOfItemToUpdate, item: itemToUpdate }),
          },
        };
      } else {
        return state;
      }
    case SORT_ACTION_RESET_SORT:
    case AUTHENTICATION_ACTION_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

function updateObjectInArray(array, action) {
  return array.map((item, index) => {
    if (index !== action.index) {
      return { ...item, checked: false };
    }
    return {
      ...item,
      ...action.item,
      checked: !item.checked,
    };
  });
}
