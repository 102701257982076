import React from 'react';
import styled from 'styled-components';
import { spacing, color, size } from 'sdk';
import MapUtils from '../../services/map/MapUtils';
import { MinusIcon, PlusIcon } from 'components';

const ZoomWrapper = styled.div`
  position: absolute;
  right: ${spacing / 2}px;
  bottom: 52px;
  z-index: 500;
`;

const ZoomControl = styled.div`
  width: ${2 * spacing}px;
  height: ${2 * spacing}px;
  background-color: ${color('smoke')};
  border-radius: ${props => (props.position === 'top' ? '4px 4px 0 0' : '0 0 4px 4px')};
  color: ${color('grey')};
  font-size: ${size('xl')};
  text-align: center;
  cursor: pointer;
  border-top: ${props => props.position === 'bottom' && '1px solid ' + color('smoke', 'dark')};
  &:hover {
    background-color: ${color('smoke', 'dark')};
  }
`;

function ZoomControls() {
  const { mapInstance } = MapUtils;
  return (
    <ZoomWrapper>
      <ZoomControl onClick={() => mapInstance.zoomIn()} position="top">
        <PlusIcon size={24} color={color('grey')} />
      </ZoomControl>
      <ZoomControl onClick={() => mapInstance.zoomOut()} position="bottom">
        <MinusIcon size={24} color={color('grey')} />
      </ZoomControl>
    </ZoomWrapper>
  );
}

export default ZoomControls;
