"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RulerIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var RulerIcon = function RulerIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    strokeWidth: "".concat(props.strokeWidth || 1),
    viewBox: '0 0 21 21'
  }, _react["default"].createElement("g", {
    xmlns: "http://www.w3.org/2000/svg"
  }, _react["default"].createElement("rect", {
    y: "0",
    fill: "none",
    width: "21",
    height: "21"
  }), _react["default"].createElement("path", {
    opacity: "0.5",
    fill: "none",
    stroke: "#000000",
    d: "M4.5,8"
  }), _react["default"].createElement("line", {
    fill: "none",
    stroke: "#000000",
    x1: "3.5",
    y1: "7",
    x2: "3.5",
    y2: "10"
  }), _react["default"].createElement("line", {
    fill: "none",
    stroke: "#000000",
    x1: "5.5",
    y1: "7",
    x2: "5.5",
    y2: "11"
  }), _react["default"].createElement("line", {
    fill: "none",
    stroke: "#000000",
    x1: "7.5",
    y1: "7",
    x2: "7.5",
    y2: "10"
  }), _react["default"].createElement("line", {
    fill: "none",
    stroke: "#000000",
    x1: "9.5",
    y1: "7",
    x2: "9.5",
    y2: "11"
  }), _react["default"].createElement("line", {
    fill: "none",
    stroke: "#000000",
    x1: "11.5",
    y1: "7",
    x2: "11.5",
    y2: "10"
  }), _react["default"].createElement("line", {
    fill: "none",
    stroke: "#000000",
    x1: "13.5",
    y1: "7",
    x2: "13.5",
    y2: "11"
  }), _react["default"].createElement("line", {
    fill: "none",
    stroke: "#000000",
    x1: "15.5",
    y1: "7",
    x2: "15.5",
    y2: "10"
  }), _react["default"].createElement("path", {
    d: "M1,6v7v2h19v-2v-7H1z M2,13v-5h17v5H2z"
  }), _react["default"].createElement("line", {
    fill: "none",
    stroke: "#000000",
    x1: "17.5",
    y1: "7",
    x2: "17.5",
    y2: "11"
  })));
};

exports.RulerIcon = RulerIcon;