import { useState, useCallback, useMemo } from 'react';

export function useParcelSelection() {
  const [selectedParcel, setSelectedParcel] = useState(null);
  const setParcel = useCallback(
    parcel => {
      if (parcel && parcel.id && selectedParcel && selectedParcel.id === parcel.id) {
        setSelectedParcel(null);
      } else {
        setSelectedParcel(parcel);
      }
    },
    [selectedParcel],
  );
  const fitTo = useMemo(() => {
    return selectedParcel ? [selectedParcel] : undefined;
  }, [selectedParcel]);
  return [fitTo, selectedParcel, setParcel];
}
