"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PersonIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var PersonIcon = function PersonIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 24 24'
  }, _react["default"].createElement("g", null, _react["default"].createElement("circle", {
    cx: "11.99",
    cy: "8.17",
    r: "4.17"
  }), _react["default"].createElement("path", {
    d: "M12 14a8.62 8.62 0 0 0-7.3 4.25 1.15 1.15 0 0 0 1 1.75h12.6a1.14 1.14 0 0 0 1-1.66A8.62 8.62 0 0 0 12 14z"
  })));
};

exports.PersonIcon = PersonIcon;