import { Button } from 'components';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Zoom';
import { color, spacing } from 'sdk';
import styled from 'styled-components';
import FiltersActioner from '../../services/filters/FiltersActioner';
import Expandable from '../modal/Expandable';
import FilterItem from './FilterItem';

function Filters({ filters, filtersExpanded, resetFilters, setFiltersExpanded, toggleFilterItem }) {
  const close = useCallback(() => setFiltersExpanded(false), [setFiltersExpanded]);
  return (
    <Expandable expanded={filtersExpanded} onRequestClose={close} title={'Filtrer'}>
      <FiltersCategories>
        {Object.keys(filters).map((k, index) => {
          const filtersTitle = filters[k].label;
          return (
            <React.Fragment key={`filters-category-${filtersTitle}`}>
              <FiltersCategory>
                <FiltersTitle>{filtersTitle}</FiltersTitle>
                <HorizontalList>
                  {filters[k].items.map((item, i) => {
                    return (
                      <Fade key={`${k}-${i}-${filtersExpanded}`} duration={200} delay={index * 250 + i * (i + 20)}>
                        <FilterItem
                          filter={item}
                          onClick={() => {
                            toggleFilterItem({ title: k, label: item.label });
                          }}
                        />
                      </Fade>
                    );
                  })}
                </HorizontalList>
              </FiltersCategory>
              {index % 2 === 0 && <Separator />}
            </React.Fragment>
          );
        })}
      </FiltersCategories>
      <FiltersActions>
        <Button backgroundColor={'bleen'} outline onClick={resetFilters}>
          Réinitialiser
        </Button>
        <Button backgroundColor={'bleen'} onClick={() => setFiltersExpanded(false)}>
          Voir les résultats
        </Button>
      </FiltersActions>
    </Expandable>
  );
}

const mapStateToProps = state => ({
  filters: state.filters,
});

const mapDispatchToProps = dispatch => ({
  toggleFilterItem: ({ label, title }) => dispatch(FiltersActioner.toggleFilterItem({ label, title })),
  resetFilters: () => dispatch(FiltersActioner.resetFilters()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);

const FiltersActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: ${spacing}px;
`;

const FiltersCategories = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizontalList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FiltersCategory = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  min-width: 150px;
`;

const FiltersTitle = styled.div`
  color: ${color('bleen')};
  font-weight: 700;
  padding-bottom: ${spacing / 4}px;
  margin-bottom: ${spacing / 4}px;
  border-bottom: 1px solid ${color('bleen')};
`;

const Separator = styled.div`
  width: ${spacing}px;
`;
