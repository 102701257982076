"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MinusIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var MinusIcon = function MinusIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 24 24'
  }, _react["default"].createElement("g", null, _react["default"].createElement("path", {
    stroke: props.color,
    strokeWidth: 2,
    d: "M18.5 12h-13"
  })));
};

exports.MinusIcon = MinusIcon;