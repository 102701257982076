import React, { useRef } from 'react';
import styled from 'styled-components';
import { useDrawParcelHintOnSvg, useParcelScales, useSvg } from './effects';

export default function({ parcel, color }) {
  const container = useRef();
  const [svg, width, height] = useSvg(container);
  const [line] = useParcelScales(parcel, width, height);
  useDrawParcelHintOnSvg(svg, parcel, line, { polygonColor: color });
  return <Container ref={container} />;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  align-item: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;
