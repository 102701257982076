import React, { useCallback } from 'react';
import Slide from 'react-reveal/Slide';
import { spacing, zIndex } from 'sdk';
import styled from 'styled-components';
import NavPageHeader from '../../components/header/NavPageHeader';
import Map from '../../components/map/map';
import { useParcelSelection } from './components/effects';
import TaskDisplay from './components/TaskDisplay';

const Task = ({ task, history }) => {
  const goBack = useCallback(() => {
    history && history.push('/');
  }, [history]);
  const [fitTo, parcel, setParcel] = useParcelSelection();

  const { parcels } = task;
  return (
    <AppWrapper>
      <NavPageHeader {...{ goBack }} title={task.name} />
      <Map fitOn={fitTo} parcels={parcels || []} />
      <Slide up>
        <Card>
          <TaskDisplay selectedParcel={parcel} onParcelClick={setParcel} {...{ task, goBack, history }} />
        </Card>
      </Slide>
    </AppWrapper>
  );
};

export default Task;

const AppWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: absolute;
  bottom: ${spacing}px;
  right: 56px;
  left: ${spacing}px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: ${zIndex('ui')};
`;
