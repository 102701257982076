export const SORT_ACTION_RESET_SORT = 'SORT_ACTION_RESET_SORT';
export const SORT_ACTION_TOGGLE_SORT_ITEM = 'SORT_ACTION_TOGGLE_SORT_ITEM';

const toggleSortItem = payload => ({
  type: SORT_ACTION_TOGGLE_SORT_ITEM,
  payload,
});

const resetSort = () => ({
  type: SORT_ACTION_RESET_SORT,
});

export default {
  toggleSortItem,
  resetSort,
};
