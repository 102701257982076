import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import ScrollArea from 'react-scrollbar';
import styled from 'styled-components';
import { color, size, spacing } from 'sdk';

function Select({ bottomAction, label, onChange, options, placeholder, selected }) {
  const popover = useRef(null);

  const handleDomClick = useCallback(
    e => {
      e.preventDefault();
      if (!popover || !popover.current || !popover.current.contains(e.target)) {
        setExpanded(false);
      }
    },
    [popover],
  );

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    document.addEventListener('click', handleDomClick);
    return () => {
      document.removeEventListener('click', handleDomClick);
    };
  }, [expanded, handleDomClick]);

  function handleTogglePopover(e) {
    if (
      !e ||
      (e.target &&
        e.target.className &&
        typeof e.target.className === 'string' &&
        e.target.className.indexOf('scrollbar') > -1)
    ) {
      return;
    }

    e.preventDefault();
    setExpanded(!expanded);
  }

  function handleSelectOption(id) {
    setExpanded(false);
    onChange(id);
  }

  const selectedLabel =
    selected && options.find(o => o.value === selected) && options.find(o => o.value === selected).label;

  return (
    <Wrapper>
      {label && <Label onClick={handleTogglePopover}>{label}</Label>}
      <SelectWrapper>
        <Trigger onClick={handleTogglePopover} selected={!!selectedLabel}>
          <TriggerSelected>{selectedLabel || placeholder}</TriggerSelected>
          <MdArrowDropDown />
        </Trigger>

        {expanded && (
          <Popover ref={popover} onClick={handleTogglePopover}>
            <ScrollArea
              style={{ maxHeight: 300 }}
              verticalScrollbarStyle={{ borderRadius: 200 }}
              stopScrollPropagation={true}
            >
              <PopoverItems>
                {options.map(({ value, label }, i) => (
                  <li key={`value${i}`}>
                    <PopoverItem
                      data-text={label}
                      selected={selected === value}
                      onClick={() => handleSelectOption(value)}
                    >
                      {label}
                    </PopoverItem>
                  </li>
                ))}
              </PopoverItems>
            </ScrollArea>
            {bottomAction && (
              <BottomAction onClick={e => bottomAction.fn(e)}>
                {bottomAction.ico ? bottomAction.ico : null}
                {bottomAction.btnLabel}
              </BottomAction>
            )}
          </Popover>
        )}
      </SelectWrapper>
    </Wrapper>
  );
}

export default Select;

const PopoverItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  color: ${color('bleen')};
`;

export const BottomAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing / 4}px;
  border-bottom-left-radius: ${spacing / 4}px;
  border-bottom-right-radius: ${spacing / 4}px;
  background-color: ${color('orange')};
  border: 1px solid ${color('orange')};
  text-align: center;
  color: white;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  svg {
    margin-right: ${spacing / 4}px;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const Label = styled.label`
  display: flex;
  font-size: ${size('xs')};
  margin: ${spacing / 2}px ${spacing / 2}px ${spacing / 2}px 0;
  color: ${color('bleen')};
`;

export const Trigger = styled.button`
  border: 1px solid ${color('bleen')};
  border-radius: 4px;
  z-index: 100;
  width: 100%;
  text-align: left;
  display: flex;
  font-size: ${size('m')};
  outline: none;
  justify-content: space-between;
  background-color: white;
  padding: ${spacing / 2}px ${spacing / 2}px;
  align-items: center;
  cursor: pointer;
  color: ${props => (props.selected ? color('bleen') : color('bleen', 'light'))};
`;

export const TriggerSelected = styled.span`
  flex: 1;
  padding: 0 ${spacing / 2}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Popover = styled.div`
  position: absolute;
  top: 34px;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 12px 24px 6px rgba(0, 0, 0, 0.15);
  z-index: 2;
`;

export const PopoverItem = styled.button`
  padding: ${spacing / 2}px ${spacing}px;
  text-align: left;
  border: 0;
  display: block;
  width: 100%;
  outline: none;
  cursor: pointer;
  color: ${color('bleen')};
  background-color: ${props => (props.selected ? 'white' : 'transparent')};
  font-size: ${size('m')};
  &:hover {
    background-color: ${color('bleen')};
    color: white;
  }
`;
