import React from 'react';
import { spacing } from 'sdk';
import { color } from 'sdk/lib/color';
import styled from 'styled-components';
import EquipmentModelIcon from './EquipmentModelIcon';

export default function({ modelId, index }) {
  return (
    <ModelIcon {...{ index }}>
      <EquipmentModelIcon size={2.5 * spacing} color={color('white')} {...{ modelId }} />
    </ModelIcon>
  );
}

const ModelIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 ${3 * spacing}px;
  width: ${3 * spacing}px;
  height: ${3 * spacing}px;
  border-radius: 100%;
  margin-right: ${spacing}px;
  background-color: ${color('bleen')};

  svg {
    color: ${props => (props.bare ? 'currentColor' : '#fff')};
    fill: ${props => (props.bare ? 'currentColor' : '#fff')};
    margin-right: 0;
    line,
    polygon {
      fill: none;
      stroke: #fff;
    }
  }
`;
