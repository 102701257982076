"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CloseIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var CloseIcon = function CloseIcon(props) {
  return _react["default"].createElement("svg", {
    height: "".concat(props.size || 24),
    width: "".concat(props.size || 24),
    viewBox: '0 0 24 24'
  }, _react["default"].createElement("g", null, _react["default"].createElement("title", null, "ic_close"), _react["default"].createElement("path", {
    fill: "none",
    stroke: props.color,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2px",
    d: "M6.34 6.34l11.32 11.32"
  }), _react["default"].createElement("path", {
    fill: "none",
    stroke: props.color,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2px",
    d: "M17.66 6.34L6.34 17.66"
  })));
};

exports.CloseIcon = CloseIcon;