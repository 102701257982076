import uniqBy from 'lodash/uniqBy';
import { FILTERS_ACTION_RESET_FILTERS, FILTERS_ACTION_TOGGLE_FILTER_ITEM } from './FiltersActioner';
import { AUTHENTICATION_ACTION_LOGOUT } from '../authentication/AuthenticationActioner';
import { TASKS_ACTION_SET_TASKS } from '../tasks/TasksActioner';

export const FILTER_TYPES = {
  EQUIPMENT_MODEL: 0,
  CLUSTER: 1,
};

const initialState = {
  equipment_model_id: {
    type: FILTER_TYPES.EQUIPMENT_MODEL,
    label: 'Types de chantier',
    items: [],
  },
  client_cluster_id: {
    type: FILTER_TYPES.CLUSTER,
    label: 'Clients',
    items: [],
  },
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case FILTERS_ACTION_TOGGLE_FILTER_ITEM:
      const { label, title } = payload;
      const items = state[title].items;
      let itemToUpdate, indexOfItemToUpdate;
      items.forEach((item, indexOfItem) => {
        if (item.label === label) {
          itemToUpdate = item;
          indexOfItemToUpdate = indexOfItem;
        }
      });
      if (itemToUpdate && typeof indexOfItemToUpdate === 'number' && indexOfItemToUpdate > -1) {
        return {
          ...state,
          [title]: {
            ...state[title],
            items: updateObjectInArray(items, { index: indexOfItemToUpdate, item: itemToUpdate }),
          },
        };
      } else {
        return state;
      }
    case TASKS_ACTION_SET_TASKS:
      let model = [],
        client = [];
      const theTasks = payload.taskIDs.map(id => payload.taskByID[id]);
      (theTasks || []).forEach(d => {
        model.push({
          type: FILTER_TYPES.EQUIPMENT_MODEL,
          instance_id: d.equipment_model_id,
          label: d.equipment_model_id,
          checked: false,
        });
        client.push({
          type: FILTER_TYPES.CLUSTER,
          instance_id: d.client_cluster_id,
          label: d.client_cluster_id,
          checked: false,
        });
      });
      return {
        ...state,
        equipment_model_id: {
          ...state.equipment_model_id,
          items: uniqBy(state.equipment_model_id.items.concat(model), m => m.label),
        },
        client_cluster_id: {
          ...state.client_cluster_id,
          items: uniqBy(state.client_cluster_id.items.concat(client), c => c.label),
        },
      };
    case FILTERS_ACTION_RESET_FILTERS:
      return {
        ...state,
        equipment_model_id: {
          ...state.equipment_model_id,
          items: state.equipment_model_id.items.map(i => ({
            ...i,
            checked: false,
          })),
        },
        client_cluster_id: {
          ...state.client_cluster_id,
          items: state.client_cluster_id.items.map(i => ({
            ...i,
            checked: false,
          })),
        },
      };
    case AUTHENTICATION_ACTION_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

function updateObjectInArray(array, action) {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item,
      checked: !item.checked,
    };
  });
}
