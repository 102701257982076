import { AUTHENTICATION_ACTION_LOGOUT } from '../authentication/AuthenticationActioner';
import { PARCELS_ACTION_SET_PARCELS, PARCELS_ACTION_SET_ERROR, PARCELS_ACTION_SET_LOADING } from './ParcelsActioner';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniqBy';

let parcelByID = {},
  parcelByClusterID = {};
let parcelIDs = [],
  parcelClusterIDs = [];

const initialState = {
  loading: false,
  error: null,
  parcelByID,
  parcelByClusterID,
  parcelIDs,
  parcelClusterIDs,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case PARCELS_ACTION_SET_LOADING:
      if (payload === state.loading) return state;
      return {
        ...state,
        loading: payload,
      };
    case PARCELS_ACTION_SET_PARCELS:
      const newParcelById = { ...state.parcelByID };
      Object.keys(payload.parcelByID).forEach(key => {
        if (!newParcelById[key]) {
          newParcelById[key] = payload.parcelByID[key];
        }
      });
      const newParcelClusterByID = { ...state.parcelByClusterID };
      Object.keys(payload.parcelByClusterID).forEach(key => {
        if (!!newParcelClusterByID[key]) {
          newParcelClusterByID[key] = uniqBy(
            newParcelClusterByID[key].concat(payload.parcelByClusterID[key]),
            p => p.id,
          );
        } else {
          newParcelClusterByID[key] = payload.parcelByClusterID[key];
        }
      });
      return {
        ...state,
        parcelByID: newParcelById,
        parcelByClusterID: newParcelClusterByID,
        parcelIDs: uniq([...state.parcelIDs, ...payload.parcelIDs]),
        parcelClusterIDs: uniq([...state.parcelClusterIDs, ...payload.parcelClusterIDs]),
        loading: false,
        error: null,
      };
    case PARCELS_ACTION_SET_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case AUTHENTICATION_ACTION_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
