import React from 'react';
import styled from 'styled-components';
import Loader from './Loader';
import { color } from 'sdk';

export default function LoadingOverlay() {
  return (
    <Container>
      <Content>
        <Loader size={100} />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  animation: bg 26s ease infinite;
  background: linear-gradient(310deg, ${color('bleen')}, ${color('bleen', 'dark')});
  @keyframes bg {
    0% {
      background-position: 0% 67%;
    }
    50% {
      background-position: 100% 34%;
    }
    100% {
      background-position: 0% 67%;
    }
  }
`;

const Content = styled.div``;
