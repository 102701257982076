import { logout } from '../authentication/AuthenticationActioner';

export default function api(call, param) {
  return dispatch => {
    return call(param)
      .then(result => {
        if (result.status === 403) {
          return Promise.reject(null);
        }
        if (result.status === 401) {
          dispatch(logout());
          return Promise.reject(null);
        }
        if (result.status === 204) {
          return Promise.resolve([]);
        }
        return Promise.resolve(result.json());
      })
      .catch(e => {
        if (e.status === 401) {
          dispatch(logout());
          return Promise.reject(null);
        }
        return Promise.reject(e);
      });
  };
}
