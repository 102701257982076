"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SeederIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var SeederIcon = function SeederIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    strokeWidth: "".concat(props.strokeWidth || 1),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M3.2 29.9h1.5c.4 0 .7-.3.7-.7v-2.3c0-.4-.3-.7-.7-.7H3.2c-.4 0-.7.3-.7.7v2.3c0 .4.3.7.7.7zM34.3 29.9h1.5c.4 0 .7-.3.7-.7v-2.3c0-.4-.3-.7-.7-.7h-1.5c-.4 0-.7.3-.7.7v2.3c0 .4.3.7.7.7zM35.6 26.3H3.2M27.4 22.2H11.5M26.5 13.3l-.6-3.5c0-.4-.3-.7-.7-.7h-12c-.4 0-.7.3-.7.7l-.6 3.5h14.6z"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M21.9 17.4h3.3c.4 0 .7-.3.7-.7l.6-3.5M11.9 13.2l.6 3.5c0 .4.3.7.7.7h3.7M21.9 17.4v2h-4.8v-2M19.3 22.2v7.7M14.7 22.2l-3.2 4.1v3.6M11.5 22.2l-8.3 4.1M17.1 19.4l-2.4 2.8"
  }), _react["default"].createElement("g", null, _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M24.3 22.2l2.8 4.1v3.6M27.4 22.2l8.4 4.1M21.9 19.4L24 22"
  })));
};

exports.SeederIcon = SeederIcon;