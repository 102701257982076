import AuthenticationSelectors from '../authentication/AuthenticationSelectors';
import UsersApi from './UsersApi';
import UsersUtils from './UsersUtils';

export const USERS_ACTION_SET_UOC = 'USERS_ACTION_SET_UOC';
export const USERS_ACTION_SET_LOADING = 'USERS_ACTION_SET_LOADING';
export const USERS_ACTION_SET_ERROR = 'USERS_ACTION_SET_ERROR';

const setLoading = payload => ({
  type: USERS_ACTION_SET_LOADING,
  payload,
});

const setError = payload => ({
  type: USERS_ACTION_SET_ERROR,
  payload,
});

const setUsersOfCluster = payload => ({
  type: USERS_ACTION_SET_UOC,
  payload,
});

const fetchUsersOfClusters = clusters => {
  return (dispatch, getState) => {
    const state = getState();
    if (clusters && clusters.length) {
      dispatch(setLoading(true));
      const clustersPromises = clusters.map(c => {
        return dispatch(UsersApi.getUsersByClusterID(AuthenticationSelectors.getState(state), c))
          .then(result => {
            const users = UsersUtils.serializeUsersFromApi(result);
            dispatch(setUsersOfCluster(users));
            return Promise.resolve(users);
          })
          .catch(e => {
            dispatch(setError(e));
            return Promise.reject(e);
          });
      });
      return Promise.all(clustersPromises);
    } else {
      return Promise.resolve(null);
    }
  };
};

const linkUserToClusterByMail = payload => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setLoading(true));
    return dispatch(
      UsersApi.linkUserToClusterByMail(AuthenticationSelectors.getState(state), payload.clusterID, payload.user),
    )
      .then(result => {
        const users = UsersUtils.serializeUsersFromApi([result]);
        dispatch(setUsersOfCluster(users));
        return Promise.resolve(result);
      })
      .catch(e => {
        dispatch(setError(e));
        return Promise.reject(e);
      });
  };
};

export default {
  fetchUsersOfClusters,
  linkUserToClusterByMail,
};
