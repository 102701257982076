import { karnottAPI } from 'sdk';
import api from '../api/ApiUtils';

export function getUsersByClusterID(authentication, clusterID) {
  return api(karnottAPI.users.get.usersOfCluster, {
    baseURI: process.env.REACT_APP_API_BASE,
    params: {
      cluster_id: clusterID,
    },
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
  });
}

export function linkUserToClusterByMail(authentication, clusterID, payload) {
  return api(karnottAPI.users.post.linkUserToClusterByMail, {
    baseURI: process.env.REACT_APP_API_BASE,
    params: {
      cluster_id: clusterID,
    },
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
    payload,
  });
}

export default {
  getUsersByClusterID,
  linkUserToClusterByMail,
};
