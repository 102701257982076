import { Button } from 'components';
import React, { useCallback } from 'react';
import { MdCheck } from 'react-icons/md';
import { connect } from 'react-redux';
import { color, spacing } from 'sdk';
import styled from 'styled-components';
import SortActioner from '../../services/sort/SortActioner';
import Expandable from '../modal/Expandable';
import { Item } from './FilterItem';

function Sort({ sort, sortExpanded, resetSort, setSortExpanded, toggleSortItem }) {
  const close = useCallback(() => setSortExpanded(false), [setSortExpanded]);
  return (
    <Expandable expanded={sortExpanded} title={'Trier'} onRequestClose={close}>
      <SortCategories>
        {Object.keys(sort).map(k => {
          const sortTitle = sort[k].label;
          return (
            <SortCategory key={`sort-category-${sortTitle}`}>
              <SortTitle>{sortTitle}</SortTitle>
              {sort[k].items.map(item => {
                return (
                  <Item
                    key={`sort-item-${item.label}`}
                    onClick={() => {
                      toggleSortItem({ title: k, label: item.label });
                    }}
                    {...{ checked: item.checked }}
                  >
                    {item.label}
                    {item.checked ? <MdCheck /> : null}
                  </Item>
                );
              })}
            </SortCategory>
          );
        })}
      </SortCategories>
      <SortActions>
        <Button backgroundColor={'bleen'} outline onClick={resetSort}>
          Réinitialiser
        </Button>
        <Button backgroundColor={'bleen'} onClick={() => setSortExpanded(false)}>
          Voir les résultats
        </Button>
      </SortActions>
    </Expandable>
  );
}

const mapStateToProps = state => ({
  sort: state.sort,
});

const mapDispatchToProps = dispatch => ({
  toggleSortItem: ({ label, title }) => dispatch(SortActioner.toggleSortItem({ label, title })),
  resetSort: () => dispatch(SortActioner.resetSort()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sort);

const SortActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const SortCategories = styled.div`
  display: flex;
`;

const SortCategory = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing}px;
  flex: 1;
  align-items: stretch;
  min-width: 150px;
`;

const SortTitle = styled.div`
  color: ${color('bleen')};
  font-weight: 700;
  padding-bottom: ${spacing / 4}px;
  margin-bottom: ${spacing / 4}px;
  border-bottom: 1px solid ${color('bleen')};
`;
