"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParcelIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ParcelIcon = function ParcelIcon(props) {
  return _react["default"].createElement("svg", {
    width: "".concat(props.size || 39),
    height: "".concat(props.size || 39),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    fill: 'transparent',
    stroke: props.color,
    d: "M28.2 12.2L14 16.3l-3.8-3 14.2-4.1zM28.5 19l-14.2 4-3.8-3 14.2-4zM28.8 25.7l-14.2 4.1-3.8-3L25 22.7z"
  }));
};

exports.ParcelIcon = ParcelIcon;