import React from 'react';
import Zoom from 'react-reveal/Zoom';
import { size } from 'sdk';
import styled from 'styled-components';

export default function({ noMargin, delay = 0, color = 'white', fontSize = 'l' }) {
  return (
    <Text {...{ noMargin, delay, color, fontSize }}>
      <Zoom duration={500} delay={delay + 300}>
        <div>
          <b>Créez</b>
        </div>
      </Zoom>
      ,
      <Zoom duration={500} delay={delay + 400}>
        <div>
          <b> organisez</b>
        </div>
      </Zoom>{' '}
      et{' '}
      <Zoom duration={500} delay={delay + 600}>
        <div>
          <b>suivez</b>
        </div>
      </Zoom>{' '}
      <span>vos travaux</span>
    </Text>
  );
}

const Text = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  b, span, div {
    font-family: 'Ubuntu', sans-serif;
  }
  ${({ noMargin }) =>
    noMargin
      ? ``
      : `
  margin-bottom: 10vh;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 5vh;`};
  color: ${({ color }) => color};
  opacity: 0.9;
  font-size:${({ fontSize }) => `${size(fontSize)}`};
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  animation: color ${({ delay = 0 }) => delay + 1800}ms ease-in-out;
  animation-fill-mode: forwards;
  div {
    margin: 0 5px;
  }
  b {
    color: ${({ color }) => color};
  }
  @keyframes color {
    0% {
      color: transparent;
    }
    90% {
      color: transparent;
    }
    100% {
      color:  ${({ color }) => color};
    }
`;
