import { HorizontalProgress, MoreVertIcon, CloseIcon } from 'components';
import React, { useMemo, useRef } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { size, spacing, zIndex } from 'sdk';
import { color } from 'sdk/lib/color';
import styled from 'styled-components';
import TasksUtils from '../../../services/tasks/TasksUtils';
import EquipmentModelBookmark from '../../equipment/EquipmentModelBookmark';
import ContextMenu from './ContextMenu';
import { useToggle, useHover } from './effects';
import ParcelProgressions from './ParcelProgressions';
import Zoom from 'react-reveal/Zoom';

export default function({ task, onClick, index, setTaskToInProgress, setTaskToToDo, setTaskToCompleted, onDelete }) {
  const cardRef = useRef();

  const stats = useMemo(() => {
    return TasksUtils.getTaskProgression(task);
  }, [task]);

  const actions = useMemo(() => {
    return [
      { type: 'todo', label: 'Passer à : À faire', onClick: () => setTaskToToDo && setTaskToToDo(task.id) },
      {
        type: 'in_progress',
        label: 'Passer à : En cours',
        onClick: () => setTaskToInProgress && setTaskToInProgress(task.id),
      },
      {
        type: 'done',
        color: 'green',
        label: 'Passer à : Terminée',
        onClick: () => setTaskToCompleted && setTaskToCompleted(task.id),
      },
      { label: 'Supprimer la tâche', color: 'red', onClick: () => onDelete && onDelete(task.id) },
    ].filter(a => a.type !== task.status);
  }, [onDelete, setTaskToCompleted, setTaskToInProgress, setTaskToToDo, task]);

  const [showContextMenu, toggleShowContextMenu] = useToggle(false);
  const [hovering, mouseEnter, mouseLeave] = useHover();

  return (
    <React.Fragment>
      <ContextMenu root={cardRef} open={showContextMenu} onRequestClose={toggleShowContextMenu} {...{ actions }} />
      <Zoom duration={task.newly_created ? 2000 : 0}>
        <Card ref={cardRef} key={task.id} elevate={showContextMenu} {...{ onClick }}>
          <Content>
            <CardHeader>
              <EquipmentModelBookmark modelId={task.equipment_model_id} {...{ index }} />
              <Name title={task.name}>{task.name}</Name>
            </CardHeader>
            <Operation>
              <Part>{task.owner_name}</Part>
              <MdArrowForward color={color('orange')} />
              <Part>{task.client_name}</Part>
            </Operation>
            <Progressions>
              <ParcelProgressions parcels={task.parcels} />
            </Progressions>
          </Content>
          <HorizontalProgress displayValue progress={stats.ratio} height={spacing / 3} />
          <EditButton hovering onMouseLeave={mouseLeave} onMouseEnter={mouseEnter} onClick={toggleShowContextMenu}>
            {showContextMenu ? (
              <CloseIcon color={color('smoke', 'dark')} />
            ) : (
              <MoreVertIcon color={hovering ? color('orange') : color('smoke', 'dark')} />
            )}
          </EditButton>
        </Card>
      </Zoom>
    </React.Fragment>
  );
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: ${color('white')};
  margin: 0 ${spacing / 4}px ${spacing / 2}px ${spacing / 4}px;
  margin-bottom: ${spacing / 2}px;
  padding: 0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  z-index: ${({ elevate }) => (elevate ? zIndex('ui', 2) : zIndex('ui'))};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  pointer-events: ${({ elevate }) => (elevate ? 'none' : 'all')};
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 ${spacing / 2}px 0;
`;
const Content = styled.div`
  padding: ${spacing}px;
  position: relative;
`;

const Operation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

const Name = styled.h4`
  font-weight: 700;
  margin: 0 ${spacing}px 0 ${spacing / 2}px;
`;

const Part = styled.p`
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
  color: ${color('grey', 'light')};
  font-size: ${size('s')};
  flex: 2;
`;

const Progressions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${spacing}px;
`;

const EditButton = styled.div`
  position: absolute;
  top: ${spacing / 4}px;
  right: ${spacing / 4}px;
  opacity: ${({ hovering }) => (hovering ? 1 : 0)};
  transition: opacity 200ms;
`;
