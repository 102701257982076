import { karnottAPI } from 'sdk';
import api from '../api/ApiUtils';

export function getTasks(authentication) {
  return api(karnottAPI.tasks.get.all, {
    baseURI: process.env.REACT_APP_API_BASE,
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
  });
}

export function createTask(authentication, payload) {
  return api(karnottAPI.tasks.post.create, {
    baseURI: process.env.REACT_APP_API_BASE,
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
    payload,
  });
}

export function getTask(authentication, taskId) {
  return api(karnottAPI.tasks.get.byID, {
    baseURI: process.env.REACT_APP_API_BASE,
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
    params: { task_id: taskId },
  });
}

export function deleteTask(authentication, taskId) {
  return api(karnottAPI.tasks.delete.byID, {
    baseURI: process.env.REACT_APP_API_BASE,
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
    params: { task_id: taskId },
  });
}

export function updateTask(authentication, taskId, payload) {
  return api(karnottAPI.tasks.put.update, {
    baseURI: process.env.REACT_APP_API_BASE,
    headers: {
      Authorization: 'Bearer ' + authentication.token,
    },
    params: { task_id: taskId },
    payload,
  });
}

export default {
  createTask,
  deleteTask,
  getTasks,
  getTask,
  updateTask,
};
