import { createSelector } from 'reselect';

export const getState = state => state.users;

const getUsersFormatted = createSelector(
  [getState],
  users => {
    return users.userIDs.map(id => {
      const user = users.userByID[id];
      const prefix = !!user.fullname.trim() ? `${user.fullname} - ` : '';
      return { value: user.id, label: `${prefix}${user.email}` };
    });
  },
);

export default {
  getState,
  getUsersFormatted,
};
