import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { color, size, spacing } from 'sdk';
import { FaFilter, FaSortAmountDown } from 'react-icons/fa';
import Filters from './Filters';
import Sort from './Sort';
import FiltersSelectors from '../../services/filters/FiltersSelectors';
import { Button } from 'components';

function FilterAndSort({ nbOfFilters }) {
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [sortExpanded, setSortExpanded] = useState(false);
  return (
    <FilterAndSortContainer>
      <Button
        backgroundColor={'bleen'}
        outline
        onClick={() => {
          setFiltersExpanded(!filtersExpanded);
          setSortExpanded(false);
        }}
      >
        <FaFilter size={16} />
        Filtrer
        {nbOfFilters ? <NbOfFilters>({nbOfFilters})</NbOfFilters> : null}
      </Button>
      <Filters {...{ filtersExpanded, setFiltersExpanded }} />
      <Button
        backgroundColor={'bleen'}
        outline
        onClick={() => {
          setSortExpanded(!sortExpanded);
          setFiltersExpanded(false);
        }}
      >
        <FaSortAmountDown size={16} />
        Trier
      </Button>
      <Sort {...{ sortExpanded, setSortExpanded }} />
    </FilterAndSortContainer>
  );
}

const mapStateToProps = state => ({
  nbOfFilters: FiltersSelectors.getNbOfFilters(state),
});

export default connect(
  mapStateToProps,
  null,
)(FilterAndSort);

const FilterAndSortContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const NbOfFilters = styled.div`
  font-size: ${size('s')};
  color: ${color('orange', 'light')};
  margin-left: ${spacing / 2}px;
`;
