import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

export default function LottieAnimation({ width, height, animationData, onAnimationLoaded, config = {} }) {
  const container = useRef(null);
  useEffect(() => {
    if (!container || !container.current) return () => {};
    const elt = container.current;
    const anim = lottie.loadAnimation({
      container: elt,
      renderer: 'svg',
      animationData,
      ...config,
    });
    onAnimationLoaded && onAnimationLoaded(anim);
    return () => {
      anim && anim.stop();
      elt.removeChild(elt.firstChild);
    };
  }, [container, animationData, onAnimationLoaded, config]);

  return <Container {...{ width, height }} ref={container} />;
}

const Container = styled.div`
  display: flex;
  background: transparent;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
