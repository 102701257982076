import { createSelector } from 'reselect';

const getState = state => state.parcels;

const getParcelsByClusterID = createSelector(
  [getState],
  parcels => {
    return parcels.parcelByClusterID;
  },
);

export default {
  getState,
  getParcelsByClusterID,
};
