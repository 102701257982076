import { HorizontalProgress } from 'components';
import React, { useCallback } from 'react';
import { color, size, spacing } from 'sdk';
import styled from 'styled-components';
import ParcelThumbnail from './ParcelThumbnail';

export default function({ parcel, light, onClick, selected }) {
  const click = useCallback(
    e => {
      e.preventDefault();
      onClick && onClick(parcel);
    },
    [parcel, onClick],
  );
  return (
    <Container {...{ selected }} onClick={click}>
      <ParcelIcon>
        <ParcelThumbnail {...{ parcel }} color={selected ? color('white') : color('orange')} />
      </ParcelIcon>
      <Spacer />
      <ProgressContainer {...{ light }}>
        <Name {...{ light }}>{parcel.name}</Name>
        <HorizontalProgress height={spacing / 3} rounded progress={Math.min(1, parcel.worked_area / parcel.area)} />
      </ProgressContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spacing / 2}px;
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'default')};
  background-color: ${({ selected }) => (selected ? color('orange', 'light') : 'transparent')};
  padding: 6px;
  transition: all 300ms;
  border-radius: 4px;
  box-shadow: ${({ selected }) => (selected ? '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)' : 'none')};
`;
const Spacer = styled.div`
  display: flex;
  width: ${spacing}px;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Name = styled.h4`
  margin: 0;
  margin-bottom: ${spacing / 2}px;
  font-size: ${({ light }) => (light ? size('m') : size('l'))}px;
  font-weight: ${({ light }) => (light ? '500' : 'bold')};
`;

const ParcelIcon = styled.div`
  display: flex;
  width: ${spacing * 2}px;
  height: ${spacing * 2}px;
`;
