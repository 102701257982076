import { AUTHENTICATION_ACTION_LOGOUT, AUTHENTICATION_ACTION_TOKEN_SUCCESS } from './AuthenticationActioner';

const initialState = {
  isAuthenticated: false,
  token: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case AUTHENTICATION_ACTION_TOKEN_SUCCESS:
      return {
        ...state,
        token: `${payload.token}`,
        isAuthenticated: true,
      };
    case AUTHENTICATION_ACTION_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
