"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.karnottAPI = exports.destroy = exports.patch = exports.put = exports.postFile = exports.post = exports.getXlsx = exports.getCsv = exports.get = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var get = function get(_ref) {
  var baseURI = _ref.baseURI,
      uri = _ref.uri,
      headers = _ref.headers,
      signal = _ref.signal;
  return fetch("".concat(baseURI).concat(uri), {
    method: 'GET',
    headers: headers,
    signal: signal
  });
};

exports.get = get;

var getCsv = function getCsv(_ref2) {
  var baseURI = _ref2.baseURI,
      uri = _ref2.uri,
      headers = _ref2.headers;
  return fetch("".concat(baseURI).concat(uri), {
    method: 'GET',
    headers: headers
  });
};

exports.getCsv = getCsv;

var getXlsx = function getXlsx(_ref3) {
  var baseURI = _ref3.baseURI,
      uri = _ref3.uri,
      headers = _ref3.headers;
  return fetch("".concat(baseURI).concat(uri), {
    method: 'GET',
    headers: _objectSpread({}, headers, {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }),
    responseType: 'arraybuffer'
  });
};

exports.getXlsx = getXlsx;

var post = function post(_ref4) {
  var baseURI = _ref4.baseURI,
      uri = _ref4.uri,
      headers = _ref4.headers,
      _ref4$payload = _ref4.payload,
      payload = _ref4$payload === void 0 ? {} : _ref4$payload;
  return fetch("".concat(baseURI).concat(uri), {
    method: 'POST',
    headers: _objectSpread({}, headers, {
      'Content-Type': 'application/json;charset=UTF-8'
    }),
    body: JSON.stringify(payload)
  });
};

exports.post = post;

var postFile = function postFile(_ref5) {
  var baseURI = _ref5.baseURI,
      uri = _ref5.uri,
      headers = _ref5.headers,
      _ref5$payload = _ref5.payload,
      payload = _ref5$payload === void 0 ? {} : _ref5$payload;
  return fetch("".concat(baseURI).concat(uri), {
    method: 'POST',
    headers: headers,
    body: payload
  });
};

exports.postFile = postFile;

var put = function put(_ref6) {
  var baseURI = _ref6.baseURI,
      uri = _ref6.uri,
      headers = _ref6.headers,
      _ref6$payload = _ref6.payload,
      payload = _ref6$payload === void 0 ? {} : _ref6$payload;
  return fetch("".concat(baseURI).concat(uri), {
    method: 'PUT',
    headers: _objectSpread({}, headers, {
      'Content-Type': 'application/json;charset=UTF-8'
    }),
    body: JSON.stringify(payload)
  });
};

exports.put = put;

var patch = function patch(_ref7) {
  var baseURI = _ref7.baseURI,
      uri = _ref7.uri,
      headers = _ref7.headers,
      _ref7$payload = _ref7.payload,
      payload = _ref7$payload === void 0 ? {} : _ref7$payload;
  return fetch("".concat(baseURI).concat(uri), {
    method: 'PATCH',
    headers: _objectSpread({}, headers, {
      'Content-Type': 'application/json;charset=UTF-8'
    }),
    body: JSON.stringify(payload)
  });
};

exports.patch = patch;

var destroy = function destroy(_ref8) {
  var baseURI = _ref8.baseURI,
      uri = _ref8.uri,
      headers = _ref8.headers;
  return fetch("".concat(baseURI).concat(uri), {
    method: 'delete',
    headers: _objectSpread({}, headers, {
      'Content-Type': 'application/json;charset=UTF-8'
    })
  });
};

exports.destroy = destroy;
var karnottAPI = {
  users: {
    get: {
      me: function me(_ref9) {
        var baseURI = _ref9.baseURI,
            headers = _ref9.headers,
            signal = _ref9.signal;
        return get({
          baseURI: baseURI,
          uri: '/users/me',
          headers: headers,
          signal: signal
        });
      },
      usersOfCluster: function usersOfCluster(_ref10) {
        var baseURI = _ref10.baseURI,
            params = _ref10.params,
            headers = _ref10.headers,
            signal = _ref10.signal;
        return get({
          baseURI: baseURI,
          uri: "/clusters/".concat(params.cluster_id, "/users"),
          headers: headers,
          signal: signal
        });
      },
      resetPassword: function resetPassword(_ref11) {
        var baseURI = _ref11.baseURI,
            params = _ref11.params,
            headers = _ref11.headers,
            signal = _ref11.signal;
        return get({
          baseURI: baseURI,
          uri: "/users/lost_password?email=".concat(params.email),
          headers: headers,
          signal: signal
        });
      }
    },
    put: {
      profile: function profile(_ref12) {
        var baseURI = _ref12.baseURI,
            params = _ref12.params,
            headers = _ref12.headers,
            payload = _ref12.payload;
        return put({
          baseURI: baseURI,
          uri: "/users/".concat(params.id),
          headers: headers,
          payload: payload
        });
      },
      password: function password(_ref13) {
        var baseURI = _ref13.baseURI,
            params = _ref13.params,
            headers = _ref13.headers,
            payload = _ref13.payload;
        return put({
          baseURI: baseURI,
          uri: "/users/".concat(params.id, "/update_password"),
          headers: headers,
          payload: payload
        });
      }
    },
    patch: {
      optin: function optin(_ref14) {
        var baseURI = _ref14.baseURI,
            params = _ref14.params,
            headers = _ref14.headers,
            payload = _ref14.payload;
        return patch({
          baseURI: baseURI,
          uri: "/users/".concat(params.id, "/optins"),
          headers: headers,
          payload: payload
        });
      }
    },
    post: {
      linkUserToClusterByMail: function linkUserToClusterByMail(_ref15) {
        var baseURI = _ref15.baseURI,
            params = _ref15.params,
            headers = _ref15.headers,
            payload = _ref15.payload;
        return post({
          baseURI: baseURI,
          uri: "/clusters/".concat(params.cluster_id, "/users"),
          headers: headers,
          payload: payload
        });
      }
    }
  },
  parcels: {
    get: {
      all: function all(_ref16) {
        var baseURI = _ref16.baseURI,
            headers = _ref16.headers,
            signal = _ref16.signal;
        return get({
          baseURI: baseURI,
          uri: '/parcels',
          headers: headers,
          signal: signal
        });
      },
      byID: function byID(_ref17) {
        var baseURI = _ref17.baseURI,
            headers = _ref17.headers,
            signal = _ref17.signal,
            params = _ref17.params;
        return get({
          baseURI: baseURI,
          uri: "/parcels/".concat(params.id),
          headers: headers,
          signal: signal
        });
      },
      byClusterID: function byClusterID(_ref18) {
        var baseURI = _ref18.baseURI,
            headers = _ref18.headers,
            signal = _ref18.signal,
            params = _ref18.params;
        return get({
          baseURI: baseURI,
          uri: "/clusters/".concat(params.cluster_id, "/parcels"),
          headers: headers,
          signal: signal
        });
      },
      anonymousParcels: function anonymousParcels(_ref19) {
        var baseURI = _ref19.baseURI,
            headers = _ref19.headers,
            signal = _ref19.signal,
            params = _ref19.params;
        return get({
          baseURI: baseURI,
          uri: "/parcels/gfr?north=".concat(params.n, "&east=").concat(params.e, "&south=").concat(params.s, "&west=").concat(params.w),
          headers: headers,
          signal: signal
        });
      }
    },
    post: {
      create: function create(_ref20) {
        var baseURI = _ref20.baseURI,
            headers = _ref20.headers,
            payload = _ref20.payload;
        return post({
          baseURI: baseURI,
          uri: "/parcels",
          headers: headers,
          payload: payload
        });
      },
      anonymousParcels: function anonymousParcels(_ref21) {
        var baseURI = _ref21.baseURI,
            headers = _ref21.headers,
            payload = _ref21.payload;
        return post({
          baseURI: baseURI,
          uri: "/parcels/gfr",
          headers: headers,
          payload: payload
        });
      }
    },
    put: {
      edit: function edit(_ref22) {
        var baseURI = _ref22.baseURI,
            params = _ref22.params,
            headers = _ref22.headers,
            payload = _ref22.payload;
        return put({
          baseURI: baseURI,
          uri: "/users/".concat(params.id),
          headers: headers,
          payload: payload
        });
      }
    },
    destroy: {
      byID: function byID(_ref23) {
        var baseURI = _ref23.baseURI,
            headers = _ref23.headers,
            params = _ref23.params;
        return destroy({
          baseURI: baseURI,
          uri: "/parcels/".concat(params.id),
          headers: headers
        });
      }
    },
    postFile: {
      importParcels: function importParcels(_ref24) {
        var baseURI = _ref24.baseURI,
            _ref24$params = _ref24.params,
            cluster_id = _ref24$params.cluster_id,
            format = _ref24$params.format,
            projectionName = _ref24$params.projectionName,
            projectionID = _ref24$params.projectionID,
            headers = _ref24$params.headers,
            payload = _ref24.payload;
        var uri = "/clusters/".concat(cluster_id, "/import_parcels?format=").concat(format);

        if (projectionName !== '' && projectionName !== null) {
          uri += "&projection_name=".concat(projectionName);
        }

        if (projectionID !== '' && projectionID !== null) {
          uri += "&projection_id=".concat(projectionID);
        }

        return postFile({
          baseURI: baseURI,
          uri: uri,
          headers: headers,
          payload: payload
        });
      }
    }
  },
  clusters: {
    get: {
      all: function all(_ref25) {
        var baseURI = _ref25.baseURI,
            headers = _ref25.headers,
            signal = _ref25.signal;
        return get({
          baseURI: baseURI,
          uri: '/clusters',
          headers: headers,
          signal: signal
        });
      },
      byID: function byID(_ref26) {
        var baseURI = _ref26.baseURI,
            headers = _ref26.headers,
            signal = _ref26.signal,
            params = _ref26.params;
        return get({
          baseURI: baseURI,
          uri: "/clusters/".concat(params.cluster_id),
          headers: headers,
          signal: signal
        });
      },
      hierarchy: function hierarchy(_ref27) {
        var baseURI = _ref27.baseURI,
            headers = _ref27.headers,
            signal = _ref27.signal,
            params = _ref27.params;
        return get({
          baseURI: baseURI,
          uri: "/clusters/".concat(params.cluster_id, "/hierarchy"),
          headers: headers,
          signal: signal
        });
      },
      byUserID: function byUserID(_ref28) {
        var baseURI = _ref28.baseURI,
            headers = _ref28.headers,
            signal = _ref28.signal,
            params = _ref28.params;
        return get({
          baseURI: baseURI,
          uri: "/users/".concat(params.user_id, "/clusters"),
          headers: headers,
          signal: signal
        });
      }
    },
    post: {
      create: function create(_ref29) {
        var baseURI = _ref29.baseURI,
            headers = _ref29.headers,
            payload = _ref29.payload;
        return post({
          baseURI: baseURI,
          uri: "/clusters",
          headers: headers,
          payload: payload
        });
      },
      linkCluster: function linkCluster(_ref30) {
        var baseURI = _ref30.baseURI,
            headers = _ref30.headers,
            payload = _ref30.payload,
            params = _ref30.params;
        return post({
          baseURI: baseURI,
          uri: "/clusters/".concat(params.parent_id, "/hierarchy/").concat(params.child_id),
          headers: headers,
          payload: payload
        });
      }
    },
    put: {
      update: function update(_ref31) {
        var baseURI = _ref31.baseURI,
            headers = _ref31.headers,
            payload = _ref31.payload,
            params = _ref31.params;
        return put({
          baseURI: baseURI,
          uri: "/clusters/".concat(params.cluster_id),
          headers: headers,
          payload: payload
        });
      }
    },
    destroy: {
      unlinkCluster: function unlinkCluster(_ref32) {
        var baseURI = _ref32.baseURI,
            headers = _ref32.headers,
            payload = _ref32.payload,
            params = _ref32.params;
        return destroy({
          baseURI: baseURI,
          uri: "/clusters/".concat(params.parent_id, "/hierarchy/").concat(params.child_id),
          headers: headers,
          payload: payload
        });
      }
    }
  },
  tasks: {
    get: {
      all: function all(_ref33) {
        var baseURI = _ref33.baseURI,
            headers = _ref33.headers,
            payload = _ref33.payload;
        return get({
          baseURI: baseURI,
          uri: "/tasks",
          headers: headers,
          payload: payload
        });
      },
      byID: function byID(_ref34) {
        var baseURI = _ref34.baseURI,
            headers = _ref34.headers,
            params = _ref34.params;
        return get({
          baseURI: baseURI,
          uri: "/tasks/".concat(params.task_id),
          headers: headers
        });
      }
    },
    post: {
      create: function create(_ref35) {
        var baseURI = _ref35.baseURI,
            headers = _ref35.headers,
            payload = _ref35.payload;
        return post({
          baseURI: baseURI,
          uri: "/tasks",
          headers: headers,
          payload: payload
        });
      }
    },
    "delete": {
      byID: function byID(_ref36) {
        var baseURI = _ref36.baseURI,
            headers = _ref36.headers,
            params = _ref36.params;
        return destroy({
          baseURI: baseURI,
          uri: "/tasks/".concat(params.task_id),
          headers: headers
        });
      }
    },
    put: {
      update: function update(_ref37) {
        var baseURI = _ref37.baseURI,
            headers = _ref37.headers,
            payload = _ref37.payload,
            params = _ref37.params;
        return put({
          baseURI: baseURI,
          uri: "/tasks/".concat(params.task_id),
          headers: headers,
          payload: payload
        });
      }
    }
  },
  equipment_models: {
    get: {
      all: function all(_ref38) {
        var baseURI = _ref38.baseURI,
            headers = _ref38.headers,
            payload = _ref38.payload;
        return get({
          baseURI: baseURI,
          uri: "/equipment_models",
          headers: headers,
          payload: payload
        });
      }
    }
  }
};
exports.karnottAPI = karnottAPI;