import { useMemo, useState, useEffect, useCallback } from 'react';
import './board.css';

export function useFormatTasksForKanban(tasks) {
  return useMemo(() => {
    const todo = tasks.filter(t => t.status === 'todo');
    const inProgress = tasks.filter(t => t.status === 'in_progress');
    const done = tasks.filter(t => t.status === 'done');
    return {
      lanes: [
        {
          id: 'todo',
          label: `À faire (${todo.length})`,
          cards: todo.map(task => ({ id: task.id, task })),
          labelStyle: {
            fontWeight: 700,
            fontSize: '14px',
          },
        },
        {
          id: 'in_progress',
          label: `En cours (${inProgress.length})`,
          cards: inProgress.map(task => ({ id: task.id, task })),
          labelStyle: {
            fontWeight: 700,
            fontSize: '14px',
          },
        },
        {
          id: 'done',
          label: `Terminée (${done.length})`,
          cards: done.map(task => ({ id: task.id, task })),
          labelStyle: {
            fontWeight: 700,
            fontSize: '14px',
          },
        },
      ],
    };
  }, [tasks]);
}

export function useAnimateOnce(delay = 1000) {
  const [animate, setAnimate] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setAnimate(false), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay, setAnimate]);
  return animate;
}

export function useHover() {
  const [isIn, setIsIn] = useState(false);

  const enter = useCallback(() => {
    setIsIn(true);
  }, [setIsIn]);

  const leave = useCallback(() => {
    setIsIn(false);
  }, [setIsIn]);

  return [isIn, enter, leave];
}

export function useToggle(initialState) {
  const [value, setValue] = useState(initialState);
  const toggle = useCallback(
    e => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      setValue(!value);
    },
    [value, setValue],
  );
  return [value, toggle];
}

export function useOnScrollAndResize() {
  const [, setEvent] = useState(null);
  useEffect(() => {
    function onScroll(e) {
      setEvent(e);
    }
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
  }, [setEvent]);
}
