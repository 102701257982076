import React, { useContext } from 'react';
import EquipmentModelsContext from '../../contexts/equipment/models/EquipmentModelsContext';

function EquipmentModelIcon({ modelId, ...props }) {
  const { getModelIconById } = useContext(EquipmentModelsContext);
  const Icon = getModelIconById(modelId);
  return <Icon color="white" {...props} />;
}

export default EquipmentModelIcon;
