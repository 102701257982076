import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function({ component: Component, isAuthenticated, path, location, ...rest }) {
  return (
    <Route
      {...{ path }}
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/oauth_access_token',
              state: { referrer: location.pathname },
            }}
          />
        )
      }
    />
  );
}
