import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import FiltersReducer from './filters/FiltersReducer';
import SortReducer from './sort/SortReducer';
import TasksReducer from './tasks/TasksReducer';
import EquipmentModelsReducer from './equipment_models/EquipmentModelsReducer';
import AuthenticationReducer from './authentication/AuthenticationReducer';
import ClustersReducer from './clusters/ClustersReducer';
import ParcelsReducer from './parcels/ParcelsReducer';
import UsersReducer from './users/UsersReducer';

export default history => {
  const router = connectRouter(history);
  return combineReducers({
    authentication: AuthenticationReducer,
    clusters: ClustersReducer,
    equipment_models: EquipmentModelsReducer,
    filters: FiltersReducer,
    parcels: ParcelsReducer,
    router,
    sort: SortReducer,
    tasks: TasksReducer,
    users: UsersReducer,
  });
};
