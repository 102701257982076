import EquipmentModelsActioner from '../equipment_models/EquipmentModelsActioner';
import ClustersActioner from '../clusters/ClustersActioner';

const initApp = () => {
  return (dispatch, getState) => {
    return Promise.all([
      dispatch(EquipmentModelsActioner.fetchEquipmentModels()),
      dispatch(ClustersActioner.fetchClusters()),
    ]);
  };
};

export default {
  initApp,
};
