import { createSelector } from 'reselect';

const getState = state => state.filters;

const getNbOfFilters = createSelector(
  [getState],
  filters => {
    let nbOfFilters = 0;
    Object.keys(filters).forEach(k => {
      const items = filters[k].items;
      items.forEach(item => {
        if (item.checked) {
          nbOfFilters++;
        }
      });
    });
    return nbOfFilters;
  },
);

export default {
  getNbOfFilters,
  getState,
};
