import React, { useCallback, useState } from 'react';
import { Button } from 'components';
import { connect } from 'react-redux';
import Expandable from '../modal/Expandable';
import styled from 'styled-components';
import { color, size, spacing } from 'sdk';
import ClustersActioner from '../../services/clusters/ClustersActioner';

function AddCluster({ addClusterExpanded, createCluster, dispatch, parentClusterID, setAddClusterExpanded }) {
  const close = useCallback(() => setAddClusterExpanded(false), [setAddClusterExpanded]);
  const [clusterName, setClusterName] = useState('');
  return (
    <Expandable expanded={addClusterExpanded} onRequestClose={close} title={'Ajouter un client'}>
      <AddClusterInputLabel htmlFor="name_of_task">Nom du client</AddClusterInputLabel>
      <TextInput name="name_of_client" onChange={e => setClusterName(e.target.value)} value={clusterName} />
      <Button
        backgroundColor={'bleen'}
        onClick={() => {
          createCluster({ name: clusterName, parentClusterID }).then(theCluster => {
            dispatch({ type: 'client_cluster_id', payload: theCluster.id });
            close();
          });
        }}
      >
        Créer le nouveau client
      </Button>
    </Expandable>
  );
}

const mapDispatchToProps = dispatch => ({
  createCluster: payload => dispatch(ClustersActioner.createCluster(payload)),
});

export default connect(
  null,
  mapDispatchToProps,
)(AddCluster);

const AddClusterInputLabel = styled.label`
  font-size: ${size('xs')};
  margin: ${spacing / 2}px ${spacing / 2}px ${spacing / 2}px 0;
  color: ${color('bleen')};
`;

const TextInput = styled.input`
  font-size: ${size('m')};
  width: 100%;
  color: ${color('grey')};
  outline: none;
  margin-top: 2px;
  margin-bottom: ${spacing / 2}px;
  border: 1px solid ${color('bleen', 'light')};
  border-radius: 4px;
  padding: ${spacing / 2}px ${spacing / 4}px;
`;
