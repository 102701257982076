"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ui = exports.color = exports.hexToRgb = void 0;
var colors = {
  orange: {
    light: '#faaf2c',
    "default": '#f7931e',
    dark: '#f67d00',
    snowed: '#f4c286'
  },
  bleen: {
    dark: '#1c6f6a',
    "default": '#238B85',
    light: '#4ea29d'
  },
  green: {
    light: '#9bde72',
    "default": '#76c22c',
    dark: '#319626',
    snowed: '#DAF0CB'
  },
  sepia: {
    light: '#a5916e',
    "default": '#8a755d',
    dark: '#75675c'
  },
  cyan: {
    light: '#2ee8eA',
    "default": '#1dd7d9'
  },
  yellow: {
    light: '#ffd641',
    "default": '#ffc900',
    dark: '#f8b800'
  },
  grey: {
    darker: '#34312c',
    dark: '#3b342b',
    "default": '#4b453b',
    light: '#918e8a'
  },
  brown: {
    "default": '#4c453b'
  },
  smoke: {
    dark: '#cbc6bf',
    "default": '#dedbd7',
    light: '#e6e4e1'
  },
  snow: {
    dark: '#ebe9e6',
    "default": '#f2f0ed',
    light: '#faf9f7'
  },
  red: {
    "default": '#ff4949'
  },
  white: {
    "default": '#ffffff'
  }
};
var colorsUi = {
  primary: '#f7931e',
  success: '#13ce66',
  error: '#ff4949',
  warning: '#ffc82c',
  disabled: {
    border: colors.smoke.dark,
    text: colors.smoke["default"],
    background: colors.snow["default"]
  }
};

var hexToRgb = function hexToRgb(hex) {
  var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? "rgba(".concat(parseInt(result[1], 16), ",").concat(parseInt(result[2], 16), ",").concat(parseInt(result[3], 16), ",").concat(opacity, ")") : null;
};

exports.hexToRgb = hexToRgb;

var color = function color(name) {
  var shade = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  return colors[name] && colors[name][shade];
};

exports.color = color;

var ui = function ui(name, part) {
  return name ? part ? colorsUi[name][part] : colorsUi[name] : null;
};

exports.ui = ui;