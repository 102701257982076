"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Dropdown = Dropdown;

var _react = _interopRequireWildcard(require("react"));

var _sdk = require("sdk");

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function Dropdown(_ref) {
  var _ref$items = _ref.items,
      items = _ref$items === void 0 ? [] : _ref$items,
      head = _ref.head;

  var _useToggle = useToggle(),
      _useToggle2 = _slicedToArray(_useToggle, 2),
      isOpen = _useToggle2[0],
      toggleOpen = _useToggle2[1];

  return _react["default"].createElement(Container, null, _react["default"].createElement(FlexContainer, null, isOpen && _react["default"].createElement(CloseLayer, {
    onClick: toggleOpen
  }), _react["default"].createElement(Header, {
    onClick: toggleOpen
  }, head), _react["default"].createElement(ItemsContainer, null, _react["default"].createElement(Items, {
    isOpen: isOpen
  }, items.map(function (_ref2) {
    var logo = _ref2.logo,
        label = _ref2.label,
        onClick = _ref2.onClick;
    return _react["default"].createElement(Item, _extends({
      key: label
    }, {
      onClick: onClick
    }), logo, _react["default"].createElement(Label, null, label));
  })))));
}

function useToggle(initialState) {
  var _useState = (0, _react.useState)(initialState),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var toggle = (0, _react.useCallback)(function () {
    setValue(!value);
  }, [value, setValue]);
  return [value, toggle];
}

var Container = _styledComponents["default"].div.withConfig({
  displayName: "dropdown__Container",
  componentId: "sc-1p10gl1-0"
})(["font-family:", ";"], (0, _sdk.family)('ui'));

var FlexContainer = _styledComponents["default"].div.withConfig({
  displayName: "dropdown__FlexContainer",
  componentId: "sc-1p10gl1-1"
})(["display:flex;flex-direction:column;position:relative;"]);

var ItemsContainer = _styledComponents["default"].div.withConfig({
  displayName: "dropdown__ItemsContainer",
  componentId: "sc-1p10gl1-2"
})(["display:flex;flex-direction:column;position:relative;"]);

var Header = _styledComponents["default"].div.withConfig({
  displayName: "dropdown__Header",
  componentId: "sc-1p10gl1-3"
})(["display:flex;justify-content:flex-end;cursor:pointer;"]);

var CloseLayer = _styledComponents["default"].div.withConfig({
  displayName: "dropdown__CloseLayer",
  componentId: "sc-1p10gl1-4"
})(["position:fixed;top:0;bottom:0;left:0;right:0;"]);

var Items = _styledComponents["default"].div.withConfig({
  displayName: "dropdown__Items",
  componentId: "sc-1p10gl1-5"
})(["position:absolute;background-color:", ";right:0px;top:", "px;border-radius:", "px;padding:", "px;z-index:", ";transition:all 200ms;opacity:", ";pointer-events:", ";box-shadow:0 3px 6px rgba(0,0,0,0.16),0 3px 6px rgba(0,0,0,0.23);min-width:100px;&:before{content:'';position:absolute;right:", "px;top:-8px;width:0;height:0;border-style:solid;border-width:0 8px 8px 8px;border-color:transparent transparent ", " transparent;z-index:9999;}"], (0, _sdk.color)('brown'), _sdk.spacing / 2, _sdk.spacing / 3, _sdk.spacing, (0, _sdk.zIndex)('header', 1), function (_ref3) {
  var isOpen = _ref3.isOpen;
  return isOpen ? 1 : 0;
}, function (_ref4) {
  var isOpen = _ref4.isOpen;
  return isOpen ? 'all' : 'none';
}, _sdk.spacing * 0.6, (0, _sdk.color)('brown'));

var Item = _styledComponents["default"].div.withConfig({
  displayName: "dropdown__Item",
  componentId: "sc-1p10gl1-6"
})(["display:flex;flex-direction:row;align-items:center;cursor:pointer;margin-top:", "px;white-space:nowrap;"], _sdk.spacing / 3);

var Label = _styledComponents["default"].div.withConfig({
  displayName: "dropdown__Label",
  componentId: "sc-1p10gl1-7"
})(["color:", ";font-size:", ";line-height:1.75em;margin-left:", "px;&:hover{color:", ";}"], (0, _sdk.color)('smoke'), (0, _sdk.size)('m'), _sdk.spacing / 3, (0, _sdk.color)('orange'));