"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MiscellaneousIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var MiscellaneousIcon = function MiscellaneousIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    strokeWidth: "".concat(props.strokeWidth || 1),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 11,
    cy: 11,
    r: 1.9
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 28,
    cy: 11,
    r: 1.9
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 19.5,
    cy: 11,
    r: 1.9
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 11,
    cy: 19.5,
    r: 1.9
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 28,
    cy: 19.5,
    r: 1.9
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 19.5,
    cy: 19.5,
    r: 1.9
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 11,
    cy: 28,
    r: 1.9
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 28,
    cy: 28,
    r: 1.9
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 19.5,
    cy: 28,
    r: 1.9
  }));
};

exports.MiscellaneousIcon = MiscellaneousIcon;