import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/route/ProtectedRoute';
import { default as SignIn } from './routes/SignIn';
import { default as Tasks } from './routes/Tasks';
import { default as AddTask } from './routes/AddTask';
import { default as Task } from './routes/Task';
import { history } from './services/store';
import 'sanitize.css';
import AuthenticationSelectors from './services/authentication/AuthenticationSelectors';
import AsyncEffect from './components/async/AsyncEffect';
import InitializationActioner from './services/initialization/InitializationActioner';
import LoadingOverlay from './components/loading/LoadingOverlay';
import EquipmentModelsProvider from './contexts/equipment/models/EquipmentModelsProvider';
import ClustersProvider from './contexts/clusters/ClustersProvider';
import { family, color } from 'sdk';

const GlobalStyle = createGlobalStyle`
  * {
    font-family:${family('body')};
  }
  html {
    background-color:${color('bleen')};
  }
`;

function App({ authentication, initApp }) {
  const [, , isAppLoading] = AsyncEffect.useAsyncCall(initApp, true);
  const isAuthenticated = !!(authentication && authentication.token);
  return (
    <React.Fragment>
      <GlobalStyle />
      <ConnectedRouter {...{ history }}>
        <EquipmentModelsProvider>
          <ClustersProvider>
            {isAppLoading ? (
              <LoadingOverlay />
            ) : (
              <Switch>
                <Route exact path="/oauth_access_token" component={SignIn} />
                <ProtectedRoute {...{ isAuthenticated }} exact path="/add" component={AddTask} />
                <ProtectedRoute {...{ isAuthenticated }} exact path="/tasks/:id" component={Task} />
                <ProtectedRoute {...{ isAuthenticated }} exact path="/" component={Tasks} />
                <Route path="/" render={() => <span>404</span>} />
              </Switch>
            )}
          </ClustersProvider>
        </EquipmentModelsProvider>
      </ConnectedRouter>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  authentication: AuthenticationSelectors.getState(state),
});
const mapDispatchToProps = (disptach, props) => ({
  initApp: () => disptach(InitializationActioner.initApp()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
