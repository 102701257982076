function serializeEquipmentModelsFromApi(apiEquipmentModels) {
  const equipmentModelByID = {};
  const equipmentModelIDs = [];
  (apiEquipmentModels || []).forEach(em => {
    equipmentModelIDs.push(em.id);
    equipmentModelByID[em.id] = em;
  });
  return { equipmentModelByID, equipmentModelIDs };
}

export default {
  serializeEquipmentModelsFromApi,
};
